import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { useContext } from 'react';
import DiscountTag from './DiscountTag';
import { AppContext } from '../../contexts/AppContext';

export default function ResidentialDiscountPricesTable() {

    const { appState } = useContext(AppContext);
    return (
        <div>
            <div className="table_container_width">
                {/* <label>{appState.i18n.prices.priceTable}</label> */}
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow style={{backgroundColor: "var(--secondary-bg)"}}>
                                <TableCell>{appState.i18n.prices.cycleSchedule.toUpperCase()}</TableCell>
                                <TableCell>{appState.i18n.prices.tarcost}</TableCell>
                                <TableCell>{appState.i18n.prices.pvp}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{appState.i18n.prices.offPeak}</TableCell>
                                <TableCell><span className="discountPrice">€0.0983/kWh</span> <span className="oldPrice">€0.1070/kWh </span><DiscountTag message={"-5,05%"}/></TableCell>
                                <TableCell><span className="discountPrice">€0.1221/kWh</span> <span className="oldPrice">€0.1328/kWh</span></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{appState.i18n.prices.peak}</TableCell>
                                <TableCell><span className="discountPrice">€0.1860/kWh</span> <span className="oldPrice">€0.1959/kWh </span> <DiscountTag message={"-8,13%"}/></TableCell>
                                <TableCell><span className="discountPrice">€0.2300/kWh</span> <span className="oldPrice">€0.2422/kWh</span></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="plans-dragToSeeMore" style={{fontSize: "9pt", color: "var(--secondary-type-color)", marginTop: "5px"}}>{appState.i18n.plans.mobileDragHorizontal}</div>
            </div>
            {/* <label>{appState.i18n.prices.priceTaxNote}</label> */}
            {/* <label>{appState.i18n.prices.networkTaxNote}</label> */}
        </div>
    )
}

