import React, { useContext, useEffect, useState } from "react";
import countries from "../../i18n/countries.json"
import { AppContext } from "../../contexts/AppContext";
import { RegistrationContext } from "../../contexts/RegistrationContext";
import ErrorValidator from "../common/ErrorValidator";
import { useImmerReducer } from 'use-immer';
import { clientInfoReducer, CHANGE_CLIENT_FULLNAME, CHANGE_CLIENT_TYPE, CHANGE_AGENT_CODE, CHANGE_CONTACT_EMAIL, CHANGE_CONTACT_COUNTRY, CHANGE_CONTACT_PHONE, CHANGE_PRIVATE_CLIENT_NIF, CHANGE_PRIVATE_CLIENT_VAT, CHANGE_CONTACT_ADDRESS, CHANGE_CONTACT_POSTALCODE, CHANGE_CONTACT_CITY, CHANGE_COMPANY_NAME } from "../../reducers/ClientInfoReducer";
import ScrollToTop from "../common/ScrollToTop";


export default function RegClientInfo() {

    const pageNumber = 1;
    // const { timer, setTimer } = useContext(RegistrationContext);
    const parameters = useContext(RegistrationContext);
    const [clientInfoState, dispatchClientInfo] = parameters.clientInfo;
    const [pages] = parameters.pages
    const [errors, setErrors] = parameters.errors;
    const { appState, sendEvent } = useContext(AppContext);
    

    const handleFieldChange = (action, e) => {
        dispatchClientInfo({ type: action, payload: e.target.value });
    }

    const validateFullName = (value) => {
        const str = value.replace(/\s+/g, '')
        if (str.length === 0) {
            setErrors({...errors, fullName: appState.i18n["validations"]["empty_field"]})
        } else {
            setErrors({...errors, fullName: ""});
        }
    }

    const validateEmail = (value) => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!value.match(mailformat)) {
            setErrors({...errors, email: appState.i18n["validations"]["email"]});
        } else {
            setErrors({...errors, email: ""});
        }
    }

    const validatePhoneNumber = (value) => {
        if (value.length < 9 || !value.match(/^[0-9]+$/)) {
            setErrors({...errors, phone: appState.i18n["validations"]["phone_pt"]});
        } else {
            setErrors({...errors, phone: ""});
        }
    }

    const validateNif = (value) => {
        if (clientInfoState.contactInfo.country === "Portugal" && (value.length !== 9 || !value.match(/^[0-9]+$/))) {
            setErrors({...errors, nif: appState.i18n["validations"]["nif_pt"]})
        } else {
            setErrors({...errors, nif: ""});
        }
    }

    const validateAddress = (value) => {
        const str = value.replace(/\s+/g, '')
        if (str.length === 0) {
            setErrors({...errors, address: appState.i18n["validations"]["empty_field"]})
        } else {
            setErrors({...errors, address: ""});
        }
    }

    const validatePostalCode = (value) => {
        const str = value.replace(/\s+/g, '')
        if (str.length === 0) {
            setErrors({...errors, postalcode1: appState.i18n["validations"]["empty_field"]})
        } else {
            setErrors({...errors, postalcode1: ""});
        }
    }

    const validateCity = (value) => {
        const str = value.replace(/\s+/g, '')
        if (str.length === 0) {
            setErrors({...errors, city: appState.i18n["validations"]["empty_field"]})
        } else {
            setErrors({...errors, city: ""});
        }
    }

    return (
        <form>
            <ScrollToTop page={pages.currentPage} />
            <div className="registration-clientData registration-area">
                <div>
                    <div className="registration-area-title">
                        {appState.i18n["registration"]["clientInfo"]}
                    </div>
                    <label className="label">{appState.i18n["registration"]["clientType"]}</label>

                    <div className="register-wrap-cont">
                        <div className="clientType-container">
                            <label className="register-clienttype-label ">
                                {appState.i18n["registration"]["private_client"]}
                            </label>
                            <input
                                className="register-clienttype-radio"
                                type="radio"
                                name="clientType"
                                value="singular"
                                checked={clientInfoState.clientInfo.type === "singular"}
                                onChange={(e) => {
                                    handleFieldChange(CHANGE_CLIENT_TYPE, e)
                                    sendEvent("Registration", "Radio", "Choose Private")
                                }}
                            />
                        </div>

                        <div className="clientType-container">
                            <label className="register-clienttype-label">
                                {appState.i18n["registration"]["company_client"]}
                            </label>
                            <input
                                className="register-clienttype-radio"
                                type="radio"
                                name="clientType"
                                value="company"
                                checked={clientInfoState.clientInfo.type === "company"}
                                onChange={(e) => {
                                    handleFieldChange(CHANGE_CLIENT_TYPE, e)
                                    sendEvent("Registration", "Radio", "Choose Company")
                                }}
                            />
                        </div>

                        <div className="clientType-container">
                            <label className="register-clienttype-label">
                                {appState.i18n["registration"]["agent_client"]}
                            </label>
                            <input
                                className="register-clienttype-radio"
                                type="radio"
                                name="clientType"
                                value="agent"
                                checked={clientInfoState.clientInfo.type === "agent"}
                                onChange={(e) => {
                                    handleFieldChange(CHANGE_CLIENT_TYPE, e)
                                    sendEvent("Registration", "Radio", "Choose Agent")
                                }}
                            />
                        </div>
                    </div>

                    {clientInfoState.clientInfo.type === "company" ? (
                        <div>
                            <div className="register-companyName-cont">
                                <label className="label">{appState.i18n["registration"]["company_name"]}</label>
                                <input
                                    onFocus={() => sendEvent("Registration", "Focus", "Company Name")}
                                    required
                                    name="companyName"
                                    onChange={(e) => { handleFieldChange(CHANGE_COMPANY_NAME, e) }}
                                    value={clientInfoState.clientInfo.companyName}
                                    className="input registration-input-large"
                                    type="text"
                                    maxLength="100"
                                />
                            </div>
                            {/* {state.form.errors.companyName ? <span className="fieldValidationErrorLabel">{state.form.errors.companyName}</span> : null} */}
                            <ErrorValidator name="companyName" value={clientInfoState.clientInfo.companyName} error={errors.companyName} rules={["companyName"]} />
                        </div>
                    ) : null}

                    {clientInfoState.clientInfo.type === "agent" ? (
                        <div>
                            <div className="register-companyName-cont">
                                <label className="label">{appState.i18n["registration"]["agent_code"]}</label>
                                <input
                                    onFocus={() => sendEvent("Registration", "Focus", "Agent Code")}
                                    required
                                    name="agentCode"
                                    onChange={(e) => { 
                                        handleFieldChange(CHANGE_AGENT_CODE, e)
                                    }}
                                    value={clientInfoState.clientInfo.agentCode}
                                    className="input registration-input-medium"
                                    type="text"
                                    maxLength="10"
                                />
                            </div>
                            <ErrorValidator error={errors.agentCode} />

                            {/* {state.form.errors.companyName ? <span className="fieldValidationErrorLabel">{state.form.errors.companyName}</span> : null} */}
                        </div>
                    ) : null}

                    <div className="register-fullName-cont">
                        <label className="label">{clientInfoState.clientInfo.type === "singular" ? appState.i18n["registration"]["private_name"] : appState.i18n["registration"]["pointOfContact"]}</label>
                        <input
                            onFocus={() => sendEvent("Registration", "Focus", "Fullname")}
                            required
                            name="fullName"
                            onChange={(e) => { 
                                handleFieldChange(CHANGE_CLIENT_FULLNAME, e)
                                validateFullName(e.target.value)
                             }}
                            value={clientInfoState.clientInfo.fullName}
                            className="input registration-input-large"
                            type="text"
                        />
                        <ErrorValidator error={errors.fullName} />

                        {/* {state.form.errors.fullName ? <span className="fieldValidationErrorLabel">{state.form.errors.fullName}</span> : null} */}
                    </div>

                    <div className="registration-input-margin">
                        <label className="label">{appState.i18n["registration"]["email"]}</label>
                        <input
                            onFocus={() => sendEvent("Registration", "Focus", "Email")}
                            required
                            name="email"
                            onChange={(e) => { 
                                handleFieldChange(CHANGE_CONTACT_EMAIL, e) 
                                validateEmail(e.target.value)
                            }}
                            value={clientInfoState.contactInfo.email}
                            className="input registration-input-large"
                            type="email"
                        />
                        <ErrorValidator error={errors.email}/>

                        {/* {state.form.errors.email ? <span className="fieldValidationErrorLabel">{state.form.errors.email}</span> : null} */}
                    </div>

                    <div className="registration-input-margin">
                        <label className="label">{appState.i18n["registration"]["country"]}</label>

                        <select
                            required
                            name="country"
                            onChange={(e) => { handleFieldChange(CHANGE_CONTACT_COUNTRY, e) }}
                            value={clientInfoState.contactInfo.country}
                            className="default-dropdown"
                            type="country">
                            {countries.map((option) => (
                                <option key={option.name} value={option.name}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                        {/* {state.form.errors.country ? <span className="fieldValidationErrorLabel">{state.form.errors.country}</span> : null} */}
                    </div>

                    <div className="register-wrap-cont">
                        <div className="registration-input-margin">
                            <label className="label">{appState.i18n["registration"]["phoneCountryCode"]}</label>
                            <label className="label">{clientInfoState.contactInfo.phoneCountryCode}</label>
                        </div>

                        <div className="registration-input-margin">
                            <label className="label">{appState.i18n["registration"]["phonenumber"]}</label>
                            <input
                                onFocus={() => sendEvent("Registration", "Focus", "Phone Number")}
                                required
                                name="phone"
                                onChange={(e) => { 
                                    handleFieldChange(CHANGE_CONTACT_PHONE, e)
                                    validatePhoneNumber(e.target.value)
                                 }}
                                value={clientInfoState.contactInfo.phone}
                                className="input registration-input-medium"
                                type="phone"
                            />
                            <ErrorValidator error={errors.phone} />
                            {/* {state.form.errors.phone ? <span className="fieldValidationErrorLabel">{state.form.errors.phone}</span> : null} */}
                        </div>
                    </div>



                            {// TO DO 
                            // FALTA ADICIONAR CAMPOS PARA QUANDO É NIF EMPRESA, PT E ESTRANGEIRO
                            }




                    {clientInfoState.contactInfo.country === "Portugal" ?
                        <div className="register-nif-cont">
                            <label className="label">{appState.i18n["registration"]["taxnumber"]}</label>
                            <input
                                onFocus={() => sendEvent("Registration", "Focus", "NIF")}
                                name="nif"
                                onChange={(e) => { 
                                    handleFieldChange(CHANGE_PRIVATE_CLIENT_NIF, e)
                                    validateNif(e.target.value)
                                 }}
                                value={clientInfoState.clientInfo.nif}
                                className="input registration-input-medium"
                                autoComplete="nif"
                                maxLength="9"
                            />
                            <ErrorValidator error={errors.nif} />
                            {/* {state.form.errors.nif ? <span className="fieldValidationErrorLabel">{state.form.errors.nif}</span> : null} */}
                        </div>

                        :

                        <div className="register-nif-cont">
                            <label className="label">{appState.i18n["registration"]["taxnumber"]} - {appState.i18n["all"]["optional"]}</label>
                            <input
                                onFocus={() => sendEvent("Registration", "Focus", "VAT")}
                                name="vat"
                                onChange={(e) => { handleFieldChange(CHANGE_PRIVATE_CLIENT_VAT, e) }}
                                value={clientInfoState.clientInfo.vat}
                                className="input registration-input-medium"
                                type="vat"
                                maxLength="9"
                            />
                        </div>
                    }


                    <div className="">
                        <label className="label">{appState.i18n["registration"]["address"]}</label>
                        <input
                            onFocus={() => sendEvent("Registration", "Focus", "Address")}
                            required
                            name="address"
                            onChange={(e) => { 
                                handleFieldChange(CHANGE_CONTACT_ADDRESS, e)
                                validateAddress(e.target.value)
                             }}
                            value={clientInfoState.contactInfo.address}
                            className="input registration-input-xxlarge"
                            type="address"
                            autoComplete="Address"
                        />
                        <ErrorValidator error={errors.address} />
                        {/* {state.form.errors.address ? <span className="fieldValidationErrorLabel">{state.form.errors.address}</span> : null} */}
                    </div>

                    <div className="register-wrap-cont">
                        <div className="registration-input-margin">
                            <label className="label">{appState.i18n["registration"]["postalcode"]}</label>
                            <input
                                onFocus={() => sendEvent("Registration", "Focus", "Postal Code")}
                                required
                                name="postalcode"
                                onChange={(e) => { 
                                    handleFieldChange(CHANGE_CONTACT_POSTALCODE, e)
                                    validatePostalCode(e.target.value)
                                 }}
                                value={clientInfoState.contactInfo.postalcode1}
                                className="input registration-input-small"
                                type="postalcode"
                            />
                            <ErrorValidator error={errors.postalcode1} />
                            {/* {state.form.errors.postalcode ? <span className="fieldValidationErrorLabel">{state.form.errors.postalcode}</span> : null} */}
                        </div>

                        <div className="registration-input-margin">
                            <label className="label">{appState.i18n["registration"]["location"]}</label>
                            <input
                                onFocus={() => sendEvent("Registration", "Focus", "City")}
                                required
                                name="city"
                                onChange={(e) => { 
                                    handleFieldChange(CHANGE_CONTACT_CITY, e)
                                    validateCity(e.target.value)
                                 }}
                                value={clientInfoState.contactInfo.city}
                                className="input registration-input-small"
                                type="city"
                            />
                            <ErrorValidator error={errors.city} />
                            {/* {state.form.errors.city ? <span className="fieldValidationErrorLabel">{state.form.errors.city}</span> : null} */}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}
