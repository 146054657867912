import React from 'react'

export default function TermsElectricNetwork({lang}) {


    const TermsEN = () => {
        return <div>
            <div>
                <h3>CONDIÇÕES DE UTILIZAÇÃO DA REDE DE MOBILIDADE ELÉTRICA</h3>
            </div>

            <div>
                <h4>1. VIGÊNCIA DAS CONDIÇÕES DE UTILIZAÇÃO DA REDE DE MOBILIDADE ELÉTRICA </h4>
                <p>As presentes Condições de Utilização da Rede de Mobilidade Elétrica (“Condições”) aplicam-se aos Utilizadores de Veículos Elétricos (“UVE”) a partir do início do pagamento dos carregamentos dos veículos elétricos, no universo de pontos de carregamento definido, em cada momento, pela EGME, que ocorrerá no dia 01 de novembro de 2018.</p>
            </div>

            <div>
                <h4>2. A REDE DE MOBILIDADE ELÉTRICA</h4>
                <p>2.1 A rede de mobilidade elétrica compreende o conjunto integrado de pontos de carregamento e demais infraestruturas, de acesso público e privativo, relacionado com o carregamento de baterias de veículos elétricos, destinando-se a permitir o acesso dos UVE à mobilidade elétrica. </p>
                <p>2.2 A existência de uma rede integrada, como é o caso da rede de mobilidade elétrica, garante a interoperabilidade entre os pontos de carregamento, os sistemas de gestão, as marcas e os sistemas de carregamento de baterias de veículos elétricos, assim como o acesso universal e equitativo dos UVE ao serviço de carregamento de baterias de veículos elétricos e demais serviços integrados na rede de mobilidade elétrica. </p>
                <p>2.3 Constituem pontos de carregamento as infraestruturas ou equipamentos dedicados exclusivamente ao carregamento de baterias de veículos elétricos, aos quais podem estar associados outros serviços relativos à mobilidade elétrica, excluindo as tomadas elétricas convencionais. </p>
                <p>2.4 As atividades principais associadas à mobilidade elétrica compreendem: </p>
                <ul>
                    <li>A comercialização de eletricidade para a mobilidade elétrica;</li>
                    <li>A operação de pontos de carregamento da rede de mobilidade elétrica;</li>
                    <li>A gestão de operações da rede de mobilidade elétrica.</li>
                </ul>
                <p>2.5 A atividade de comercialização de eletricidade para a mobilidade elétrica só pode ser exercida por operadores de pontos de carregamento devidamente licenciados para o efeito pela Direção Geral de Energia e Geologia (DGEG) e registados enquanto Comercializadores de Eletricidade para a Mobilidade Elétrica (“CEME”) pela mesma entidade - DGEG.</p>
                <p>2.6 A atividade de operação de pontos de carregamento só pode ser exercida por entidades devidamente licenciadas para o efeito pela DGEG, enquanto Operadores de Pontos de Carregamento (“OPC”).</p>
                <p>2.7 A gestão de operações da rede de mobilidade elétrica corresponde à gestão dos fluxos energéticos e financeiros associados às operações da rede de mobilidade elétrica, bem como à gestão da respetiva plataforma, sendo esta atividade exercida pela Entidade Gestora da Rede de Mobilidade Elétrica (“EGME”).</p>
                <p>2.8 De acordo com a estrutura do sistema de mobilidade elétrica, para carregar as baterias do seu veículo o UVE estabelece um contrato com um ou mais CEME, que presta o serviço de comercialização de eletricidade para a mobilidade elétrica.</p>
                <p>2.9 Não estando prevista a existência de relação contratual entre UVE e OPC, a contrapartida pela utilização dos pontos de carregamento do OPC é paga pelo UVE ao respetivo CEME, procedendo posteriormente o CEME ao pagamento dessa mesma contrapartida ao OPC.</p>
                <p>2.10 Na Fase Piloto da Mobilidade Elétrica (“Fase Piloto”), iniciada em 2011, não há lugar a qualquer pagamento por parte dos UVE que carregam os seus veículos na rede de mobilidade elétrica.</p>
                <p>2.11 O encerramento da Fase Piloto prevê-se que ocorra entre 2018 e 2019, coincidindo o encerramento da Fase Piloto com o início do pagamento pelos UVE dos carregamentos que venham a efetuar. </p>
                <p>2.12 É previsível que o encerramento da Fase Piloto venha a ocorrer de forma faseada, em função da potência dos pontos de carregamento entretanto instalados e a instalar.</p>
                <p>2.13 Em concreto, os pontos de carregamento podem ser de potência normal ou de alta potência (rápido), sendo no primeiro caso caracterizados por permitirem a transferência de eletricidade para um veículo elétrico com potência inferior ou igual a 22kW e, no segundo, por permitirem a transferência de eletricidade com potência superior a 22 kW. </p>
                <p>2.14  A publicação das datas oficiais para o início do pagamento, pelos UVE, do carregamento de veículos elétricos nos postos de carregamento normal e de alta potência, é da responsabilidade da EGME.</p>
            </div>

            <div>
                <h4>3. PONTOS DE CARREGAMENTO INTEGRADOS NA REDE DE MOBILIDADE ELÉTRICA</h4>
                <p>3.1 Com o objetivo de proceder ao carregamento de baterias de veículos elétricos, o UVE pode aceder a qualquer ponto de carregamento de acesso público, obrigando-se a respeitar as regras de utilização e condições técnicas e de segurança afixadas no local.</p>
                <p>3.2 O carregamento de baterias de veículos elétricos não pressupõe a realização prévia de qualquer negócio ou a existência de um contrato entre o UVE e o OPC.</p>
                <p>3.3 A remuneração pelos serviços prestados pelos OPC e respetivo custo direto, ou indireto, imputado ao UVE, não pode ser discriminatório em função do CEME contratado pelo UVE.</p>
                <p>3.4 Aos UVE deve ser disponibilizada informação adequada sobre os preços e as condições comerciais de acesso aos pontos de carregamento, por parte dos OPC, devendo essa informação estar obrigatoriamente visível no ponto de carregamento, não obstante poder ser disponibilizada através outros meios, designadamente API ou outros meios eletrónicos.</p>
                <p>3.5 O OPC deverá permitir o acesso dos UVE, independentemente do CEME contratado por estes, aos pontos de carregamento por si explorados.</p>
                <p>3.6 O preço devido pelos serviços de carregamento praticado em cada ponto de carregamento é cobrado pelo OPC ao CEME, que por sua vez repercute a remuneração devida ao OPC na fatura do UVE emitida ao abrigo das Condições Particulares do Contrato entre o UVE e o CEME.</p>
                <p>3.7 O acesso aos pontos de carregamento de acesso privativo, mas integrados na rede de mobilidade elétrica, está dependente do preenchimento, por parte do UVE, das respetivas condições exclusivamente determinadas pelo respetivo detentor do ponto de carregamento e/ou o proprietário do espaço.</p>
                <p>3.8 Os operadores de pontos de carregamento só podem discriminar o acesso aos respetivos pontos de carregamento em casos de incompatibilidade técnica. </p>
                <p>3.9 A EGME disponibiliza, em tempo real, informação do estado de todos os pontos de carregamento localizados em espaço de acesso público, designadamente, informação do estado de operacionalidade e de utilização (em carregamento versus em stand by) dos pontos, assim como das características técnicas dos mesmos.</p>
                <p>3.10 Em caso de discrepância entre a informação, relativa à quantidade de energia elétrica consumida em determinado carregamento, exibida no ponto de carregamento logo aquando do termo do carregamento, a informação exibida no veículo elétrico e a informação posteriormente incluída na fatura que o UVE venha a receber do CEME relativa à quantidade de energia consumida naquele mesmo carregamento, prevalece, para todos os efeitos, a informação constante da fatura recebida pelo UVE.</p>
                <p>3.11 O CEME obriga-se a informar o UVE, aquando da celebração do contrato de fornecimento de energia, do direito do OPC ao exercício da prerrogativa prevista no ponto 9. das “Condições Gerais” constantes do Acordo de Adesão ao “Sistema de Gestão de Informação Integrado” dos Fluxos Energéticos e Financeiros Associados às Operações da Rede de Mobilidade Elétrica.</p>
            </div>

            <div>
                <h4>4. SERVIÇO PRESTADO AO UVE</h4>
                <p>4.1 Os serviços associados à mobilidade elétrica devem obedecer aos padrões de qualidade estabelecidos nos Regulamentos publicados pela ERSE aplicáveis ao Setor da Mobilidade Elétrica e aos níveis estabelecidos nas presentes Condições. </p>
                <p>4.2 A EGME gere um sistema de informação que permite informar o UVE dos respetivos consumos na rede de mobilidade elétrica, de acordo com métricas de tempo e energia, utilizadas na determinação dos preços dos serviços prestados por parte dos OPC e dos CEME, assim como de outras tarifas, taxas e impostos aplicáveis e indexados as estas mesmas métricas.</p>
                <p>4.3 No caso em que o OPC não dispõe de estabelecimento com caráter fixo ou permanente no qual sejam prestados serviços de atendimento ao público que compreendam o contacto direto com o mesmo, encontra-se dispensado do cumprimento da obrigação de disponibilização de livro de reclamações, devendo, neste caso, disponibilizar no seu sítio na Internet instrumentos que permitam a receção de reclamações dos UVE e afixar, em local bem visível e com carateres facilmente legíveis pelo UVE, um letreiro com indicação de como poderão ser processadas as reclamações.</p>
                <p>4.4 Quaisquer avarias ou falhas técnicas relacionadas com o funcionamento dos pontos de carregamento devem ser comunicadas ao respetivo OPC, para o contacto telefónico afixado no local, que deve estar permanentemente disponível e não comportar quaisquer custos para o UVE, podendo ainda ser comunicadas à EGME e ao respetivo CEME. Mediante acordo prévio entre o OPC e a EGME, o contacto telefónico referido anteriormente poderá ser o da linha de atendimento MOBI.E. </p>
                <p>4.5 Quando pretenda comunicar com a EGME em comunicações urgentes (cabos presos ou dificuldades/impossibilidade de carregamento), o UVE tem disponível a linha de atendimento MOBI.E (24 horas por dia), através do número de telefone: 800 916 624.</p>
                <p>4.6  Não obstante a EGME proceder de imediato à tentativa de resolução, por via remota, dos problemas/avarias identificadas, sempre que se confirme que a origem da falha é no ponto de carregamento (no equipamento), o respetivo OPC será imediatamente notificado, podendo, caso não seja possível resolver o problema/avaria de forma remota, ser chamado a intervir no local.</p>
                <p>4.7 Em caso de ocorrências não urgentes e que não resultem na impossibilidade de o UVE seguir viagem, designadamente as resultantes de situações de cabo preso ou insuficiente autonomia do veículo para a deslocação até ao ponto operacional mais próximo, os UVE poderão entrar em contato com a EGME através do e-mail: mobie@mobie.pt. </p>
                <p>4.8 Em caso de contacto, pedido de informação ou reclamação dirigida por um UVE a um OPC ou à EGME, os UVE deverão identificar-se e comunicar o ID externo do seu Cartão (“Cartão”). </p>
                <p>4.9 No caso das reclamações ou de reporte de avarias/cabos presos, a comunicação entre a EGME e o OPC e o CEME envolvidos deverá conter uma descrição dos motivos reclamados e demais elementos informativos facilitadores ou complementares para a caracterização da situação, além dos elementos identificados no ponto anterior. </p>
                <p>4.10 Todo e qualquer contacto de um UVE realizado através da linha de apoio MOBI.E ou do e-mail mobie@mobie.pt da EGME será transmitido para o respetivo CEME com quem o UVE tem Contrato para o fornecimento de eletricidade para a mobilidade elétrica, para que EGME e CEME disponham da mesma informação.</p>
            </div>

            <div>
                <h4>5. TEMPOS DE RESPOSTA A INCIDENTES E AVARIAS</h4>
                <p>5.1 O tempo máximo de resposta, por parte do OPC, a situações de cabo preso do lado do ponto de carregamento é de quatro horas, iniciando-se a contagem de tempo com a receção, pelo OPC, da comunicação proveniente do UVE ou da EGME.</p>
                <p>5.2 Nas situações que coloquem em causa a segurança dos UVE, dos utentes do local ou da via onde se encontra o ponto de carregamento o tempo máximo de resposta previsto no ponto anterior é reduzido para um máximo de 2 horas, iniciando-se a contagem de tempo com a receção, pelo OPC, da comunicação proveniente do UVE ou da EGME. </p>
                <p>5.3 A reposição das condições normais de funcionamento de um ponto de carregamento cuja avaria não se enquadre nos pontos 5.1. ou 5.2. deverá ser assegurada pelo OPC num máximo de 72 horas, iniciando-se a contagem de tempo com a receção, pelo OPC, da comunicação proveniente do UVE ou da EGME.</p>
                <p>5.4 Em situações excecionais de avaria, que não se enquadrem nos pontos 5.1. e 5.2. e que sejam avaliadas e efetivamente aceites como excecionais pela EGME, o tempo máximo de resposta será alargado até ao limite de 120 horas, iniciando-se a contagem de tempo com a receção, pelo OPC, da comunicação proveniente do UVE ou da EGME.</p>
                <p>5.5 Em situações de avaria ou falhas de comunicação superiores a 72 horas, o ponto de carregamento será temporariamente retirado dos sistemas de informação da EGME, destinados a informar os UVE, de modo a que a informação veiculada pelo sistema não induza os UVE em erro.</p>
                <p>5.6 Logo que as condições de operacionalidade e de segurança do ponto de carregamento sejam restabelecidas, e após verificação do bom funcionamento das comunicações entre o ponto de carregamento e o Sistema de Gestão da EGME, o ponto de carregamento será reintroduzido nos sistemas de informação da EGME. </p>
            </div>

            <div>
                <h4>6. MEIOS DE ACESSO AOS PONTOS DE CARREGAMENTO INTEGRADOS NA REDE DE MOBILIDADE ELÉTRICA</h4>
                <p>6.1 O O acesso de UVE aos pontos de carregamento pode realizar-se de dois modos: </p>
                <ul>
                    <li>Cartão disponibilizado pelo CEME com tecnologia RFID (identificação por rádio frequência) de acesso aos pontos de carregamento da rede de mobilidade elétrica que identifica o UVE na rede, disponibiliza o ponto para carregamento e associa os carregamentos ao presente Contrato;</li>
                    <li> Através de API ou outros meios eletrónicos, que poderão ser disponibilizados pelo CEME, e que permitirão, entre outras funcionalidades, a identificação e autenticação dos respetivos clientes UVE, a visualização dos pontos disponíveis, a seleção do ponto de carregamento a utilizar, assim como dar início e terminar carregamentos de forma remota. </li>
                </ul>
                <p>6.2 O Cartão e outros meios de acesso aos pontos de carregamento integrados na rede de mobilidade elétrica são da exclusiva propriedade do CEME, que procede à sua emissão, ao respetivo pedido de ativação, junto da EGME, na rede de mobilidade elétrica, assim como à associação a um contrato com o UVE.</p>
                <p>6.3 As operações realizadas através da utilização do seu Cartão ou outro meio de acesso consideram-se autorizadas, salvo se tiver sido previamente comunicado o extravio ou o roubo do Cartão.</p>
                <p>6.4 A obrigatoriedade de introdução de PIN para aceder e utilizar o ponto de carregamento depende do tipo de equipamento de carregamento, existindo equipamentos na rede de mobilidade elétrica que dispensam a sua introdução.</p>
                <p>6.5 O Cartão RFID incorpora na frente a identificação externa (“ID Externo”) do mesmo.</p>
                <p>6.6 O Cartão incorpora uma identificação interna (“ID Interno”) que é única, no sentido em que não existe outro Cartão, em todo o mundo, com o mesmo ID Interno, e que apenas é visível com equipamento de leitura.</p>
                <p>6.7 O Cartão é pessoal e intransmissível.</p>
                <p>6.8 Os meios de acesso através de API (tokens e passwords de acesso) e outros meios eletrónicos são pessoais e intransmissíveis, devendo ser do exclusivo conhecimento do Titular, não podendo ser divulgados ou cedidos a terceiros.</p>
                <p>6.9 O UVE Titular deve certificar-se periodicamente de que o Cartão permanece na sua posse, de modo a poder aperceber-se o mais cedo possível de alguma eventual ocorrência.</p>
                <p>6.10 O Cartão deve ser restituído ao CEME que procedeu à sua emissão sempre que se verifique a sua indevida ou inadequada utilização, que coloque em risco a segurança do mesmo, e ainda nos demais casos previstos nas presentes Condições e na lei.</p>
                <p>6.11 O Titular obriga-se a comunicar imediatamente ao respetivo CEME qualquer ocorrência, logo que dela tenha conhecimento, como a perda, o furto, o roubo, a falsificação, a apropriação abusiva ou qualquer utilização não autorizada do Cartão ou dos acessos através de API e outros meios eletrónicos.</p>
                <p>6.12 A comunicação das ocorrências mencionadas no ponto anterior, verificadas quer em Portugal quer no estrangeiro, deverá ser de imediato dirigida ao respetivo CEME, através dos meios identificados nas Condições Particulares, que deverão funcionar 24 horas por dia. Mediante acordo prévio entre o CEME e a EGME, o contacto telefónico referido anteriormente poderá ser o da linha de atendimento MOBI.E.</p>
                <p>6.13 O UVE Titular deverá também participar às autoridades policiais/judiciais locais as ocorrências, apresentando certidão do respetivo auto de denúncia ao respetivo CEME.</p>
                <p>6.14 O acesso a terceiros à informação identificada no ponto anterior deverá ser imediatamente comunicado ao CEME que procedeu à criação e disponibilização dos meios de acesso. </p>
                <p>6.15 Ao mesmo contrato podem estar associados um ou mais Cartões e/ou meios alternativos ou complementares de acesso à rede de mobilidade elétrica, emitidos pelo CEME.</p>
                <p>6.16 Sendo previsível que a data oficial para o início do pagamento nos pontos de carregamento rápido seja anterior à data oficial para o início do pagamento nos pontos de carregamento normal, entre um momento e o outro, o Cartão e restantes meios de acesso continuarão a dar acesso a todos os pontos da rede de mobilidade elétrica, sendo que a sua utilização nos pontos de carregamento normal permanecerá gratuita nessa primeira fase, passando o carregamento nos pontos de carregamento rápido a ser pago de acordo com as Condições Particulares. </p>
                <p>6.17 Em condições excecionais e exclusivamente relacionadas com a segurança dos UVE e dos restantes utilizadores dos espaços nos quais se encontram instalados os pontos de carregamento, a EGME poderá bloquear o acesso a todos os carregamentos no ponto ou pontos em causa. </p>
            </div>
            
            <div>
                <h4>7. BLOQUEIO DO ACESSO À REDE DE MOBILIDADE ELÉTRICA</h4>
                <p>7.1 A utilização do Cartão ou de meios alternativos de acesso aos pontos da rede de mobilidade elétrica pode ser bloqueada pela EGME por motivos objetivos e fundamentados, que se relacionem com:</p>
                <p>a) A segurança da utilização dos pontos de carregamento de baterias de veículos elétricos; </p>
                <p>b) A segurança do sistema de gestão da rede de mobilidade elétrica; </p>
                <p>c) Incumprimento contratual do CEME, com o qual o UVE tem Contrato, perante um ou mais agentes da mobilidade elétrica ou do setor elétrico.</p>
                <p>7.2 A utilização do Cartão ou de meios alternativos de acesso aos pontos da rede de mobilidade elétrica pode ser bloqueada pela EGME a pedido do CEME de acordo com as condições Gerais do Contrato entre o UVE e o CEME. </p>
                <p>7.3 Nos casos referidos nos números anteriores, o CEME informará o UVE Titular, por escrito, do bloqueio da utilização do Cartão e da respetiva justificação, se possível antes de bloquear o Cartão ou, o mais tardar, imediatamente após o bloqueio, salvo se tal informação não puder ser prestada por razões de segurança objetivamente fundamentadas ou se for proibida por outras disposições legais ou regulamentares aplicáveis.</p>
                <p>7.4 Logo que deixem de se verificar os motivos que levaram ao bloqueio do Cartão, o CEME pode solicitar à EGME o desbloqueio da utilização do mesmo.</p>
            </div>

            <div>
                <h4>8. OUTROS DEVERES DOS UVE</h4>
                <p>8.1 É responsabilidade do UVE informar-se devidamente e com a necessária antecedência sobre o funcionamento dos pontos, seguindo as instruções dadas pelo OPC.</p>
                <p>8.2 Sempre que reparar na existência de alguma ocorrência num ponto de carregamento o UVE deverá informar o respetivo OPC. </p>
                <p>8.3 Quando em espaço público, o UVE deverá utilizar o lugar de estacionamento apenas no tempo estritamente indispensável para proceder ao carregamento, sem prejuízo das regras próprias de cada OPC, designadamente, em termos do limite de tempo em que, uma vez terminado o carregamento, o veículo elétrico deve ser retirado do local, sendo o UVE atempadamente informado da situação de incumprimento em que poderá vir a incorrer.</p>
                <p>8.4 Findo o período de extensão estipulado no número anterior, o UVE encontra-se em situação de estacionamento indevido, estando assim sujeito à atuação das entidades fiscalizadoras do estacionamento. </p>
            </div>

            <div>
                <h4>9. POLÍTICA DE PROTEÇÃO DE DADOS PESSOAIS</h4>
                <p>9.1 Sempre que, por motivos operacionais, a EGME tenha acesso a dados pessoais dos UVE, nomeadamente para a realização de notificações em tempo real ou no âmbito de contactos ou de reclamações efetuadas pelos UVE, a EGME trata esses dados pessoais de acordo com os termos previstos na lei, nomeadamente no Regulamento Geral sobre a Proteção de Dados, aprovado pelo Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho, de 27 de abril de 2016 (“RGPD”), bem como em toda a legislação complementar aplicável. </p>
                <p>9.2 Quando a EGME tenha acesso a dados pessoais de um UVE, são facultadas ao referido UVE, nomeadamente, as informações previstas nos artigos 13.º e 14.º do RGPD, consoante, respetivamente, os dados pessoais tenham sido recolhidos junto do UVE, ou os dados pessoais não tenham sido recolhidos junto do UVE. </p>
            </div>

            <div>
                <h4>10. ALTERAÇÃO DAS CONDIÇÕES DE UTILIZAÇÃO DA REDE DE MOBILIDADE ELÉTRICA</h4>
                <p>10.1 As Condições podem ser alteradas mediante comunicação escrita, em suporte papel ou noutro suporte duradouro, dirigida ao UVE Titular.</p>
                <p>10.2 As Condições do presente contrato poderão estar sujeitas a alterações decididas e formalmente comunicadas pela EGME aos CEME, no âmbito das atribuições daquela, mantendo-se, em qualquer situação, a obrigação relativa à comunicação.</p>
                <p>10.3 proposta de alteração das Condições será comunicada com uma antecedência mínima de 14 dias antes da data proposta para a sua entrada em vigor, considerando-se que o UVE Titular aceitou as alterações propostas se não tiver comunicado, por escrito e antes da data proposta para as mesmas entrarem em vigor, que não as aceita.</p>
                <p>10.4 No caso de o UVE Titular não aceitar as alterações propostas, fica o mesmo investido do direito de denunciar o Contrato com o CEME. </p>
            </div>
    </div>
    }

    const TermsPT = () => {
        return <div>
            <div>
                <h3>CONDIÇÕES DE UTILIZAÇÃO DA REDE DE MOBILIDADE ELÉTRICA</h3>
            </div>

            <div>
                <h4>1. VIGÊNCIA DAS CONDIÇÕES DE UTILIZAÇÃO DA REDE DE MOBILIDADE ELÉTRICA </h4>
                <p>As presentes Condições de Utilização da Rede de Mobilidade Elétrica (“Condições”) aplicam-se aos Utilizadores de Veículos Elétricos (“UVE”) a partir do início do pagamento dos carregamentos dos veículos elétricos, no universo de pontos de carregamento definido, em cada momento, pela EGME, que ocorrerá no dia 01 de novembro de 2018.</p>
            </div>

            <div>
                <h4>2. A REDE DE MOBILIDADE ELÉTRICA</h4>
                <p>2.1 A rede de mobilidade elétrica compreende o conjunto integrado de pontos de carregamento e demais infraestruturas, de acesso público e privativo, relacionado com o carregamento de baterias de veículos elétricos, destinando-se a permitir o acesso dos UVE à mobilidade elétrica. </p>
                <p>2.2 A existência de uma rede integrada, como é o caso da rede de mobilidade elétrica, garante a interoperabilidade entre os pontos de carregamento, os sistemas de gestão, as marcas e os sistemas de carregamento de baterias de veículos elétricos, assim como o acesso universal e equitativo dos UVE ao serviço de carregamento de baterias de veículos elétricos e demais serviços integrados na rede de mobilidade elétrica. </p>
                <p>2.3 Constituem pontos de carregamento as infraestruturas ou equipamentos dedicados exclusivamente ao carregamento de baterias de veículos elétricos, aos quais podem estar associados outros serviços relativos à mobilidade elétrica, excluindo as tomadas elétricas convencionais. </p>
                <p>2.4 As atividades principais associadas à mobilidade elétrica compreendem: </p>
                <ul>
                    <li>A comercialização de eletricidade para a mobilidade elétrica;</li>
                    <li>A operação de pontos de carregamento da rede de mobilidade elétrica;</li>
                    <li>A gestão de operações da rede de mobilidade elétrica.</li>
                </ul>
                <p>2.5 A atividade de comercialização de eletricidade para a mobilidade elétrica só pode ser exercida por operadores de pontos de carregamento devidamente licenciados para o efeito pela Direção Geral de Energia e Geologia (DGEG) e registados enquanto Comercializadores de Eletricidade para a Mobilidade Elétrica (“CEME”) pela mesma entidade - DGEG.</p>
                <p>2.6 A atividade de operação de pontos de carregamento só pode ser exercida por entidades devidamente licenciadas para o efeito pela DGEG, enquanto Operadores de Pontos de Carregamento (“OPC”).</p>
                <p>2.7 A gestão de operações da rede de mobilidade elétrica corresponde à gestão dos fluxos energéticos e financeiros associados às operações da rede de mobilidade elétrica, bem como à gestão da respetiva plataforma, sendo esta atividade exercida pela Entidade Gestora da Rede de Mobilidade Elétrica (“EGME”).</p>
                <p>2.8 De acordo com a estrutura do sistema de mobilidade elétrica, para carregar as baterias do seu veículo o UVE estabelece um contrato com um ou mais CEME, que presta o serviço de comercialização de eletricidade para a mobilidade elétrica.</p>
                <p>2.9 Não estando prevista a existência de relação contratual entre UVE e OPC, a contrapartida pela utilização dos pontos de carregamento do OPC é paga pelo UVE ao respetivo CEME, procedendo posteriormente o CEME ao pagamento dessa mesma contrapartida ao OPC.</p>
                <p>2.10 Na Fase Piloto da Mobilidade Elétrica (“Fase Piloto”), iniciada em 2011, não há lugar a qualquer pagamento por parte dos UVE que carregam os seus veículos na rede de mobilidade elétrica.</p>
                <p>2.11 O encerramento da Fase Piloto prevê-se que ocorra entre 2018 e 2019, coincidindo o encerramento da Fase Piloto com o início do pagamento pelos UVE dos carregamentos que venham a efetuar. </p>
                <p>2.12 É previsível que o encerramento da Fase Piloto venha a ocorrer de forma faseada, em função da potência dos pontos de carregamento entretanto instalados e a instalar.</p>
                <p>2.13 Em concreto, os pontos de carregamento podem ser de potência normal ou de alta potência (rápido), sendo no primeiro caso caracterizados por permitirem a transferência de eletricidade para um veículo elétrico com potência inferior ou igual a 22kW e, no segundo, por permitirem a transferência de eletricidade com potência superior a 22 kW. </p>
                <p>2.14  A publicação das datas oficiais para o início do pagamento, pelos UVE, do carregamento de veículos elétricos nos postos de carregamento normal e de alta potência, é da responsabilidade da EGME.</p>
            </div>

            <div>
                <h4>3. PONTOS DE CARREGAMENTO INTEGRADOS NA REDE DE MOBILIDADE ELÉTRICA</h4>
                <p>3.1 Com o objetivo de proceder ao carregamento de baterias de veículos elétricos, o UVE pode aceder a qualquer ponto de carregamento de acesso público, obrigando-se a respeitar as regras de utilização e condições técnicas e de segurança afixadas no local.</p>
                <p>3.2 O carregamento de baterias de veículos elétricos não pressupõe a realização prévia de qualquer negócio ou a existência de um contrato entre o UVE e o OPC.</p>
                <p>3.3 A remuneração pelos serviços prestados pelos OPC e respetivo custo direto, ou indireto, imputado ao UVE, não pode ser discriminatório em função do CEME contratado pelo UVE.</p>
                <p>3.4 Aos UVE deve ser disponibilizada informação adequada sobre os preços e as condições comerciais de acesso aos pontos de carregamento, por parte dos OPC, devendo essa informação estar obrigatoriamente visível no ponto de carregamento, não obstante poder ser disponibilizada através outros meios, designadamente API ou outros meios eletrónicos.</p>
                <p>3.5 O OPC deverá permitir o acesso dos UVE, independentemente do CEME contratado por estes, aos pontos de carregamento por si explorados.</p>
                <p>3.6 O preço devido pelos serviços de carregamento praticado em cada ponto de carregamento é cobrado pelo OPC ao CEME, que por sua vez repercute a remuneração devida ao OPC na fatura do UVE emitida ao abrigo das Condições Particulares do Contrato entre o UVE e o CEME.</p>
                <p>3.7 O acesso aos pontos de carregamento de acesso privativo, mas integrados na rede de mobilidade elétrica, está dependente do preenchimento, por parte do UVE, das respetivas condições exclusivamente determinadas pelo respetivo detentor do ponto de carregamento e/ou o proprietário do espaço.</p>
                <p>3.8 Os operadores de pontos de carregamento só podem discriminar o acesso aos respetivos pontos de carregamento em casos de incompatibilidade técnica. </p>
                <p>3.9 A EGME disponibiliza, em tempo real, informação do estado de todos os pontos de carregamento localizados em espaço de acesso público, designadamente, informação do estado de operacionalidade e de utilização (em carregamento versus em stand by) dos pontos, assim como das características técnicas dos mesmos.</p>
                <p>3.10 Em caso de discrepância entre a informação, relativa à quantidade de energia elétrica consumida em determinado carregamento, exibida no ponto de carregamento logo aquando do termo do carregamento, a informação exibida no veículo elétrico e a informação posteriormente incluída na fatura que o UVE venha a receber do CEME relativa à quantidade de energia consumida naquele mesmo carregamento, prevalece, para todos os efeitos, a informação constante da fatura recebida pelo UVE.</p>
                <p>3.11 O CEME obriga-se a informar o UVE, aquando da celebração do contrato de fornecimento de energia, do direito do OPC ao exercício da prerrogativa prevista no ponto 9. das “Condições Gerais” constantes do Acordo de Adesão ao “Sistema de Gestão de Informação Integrado” dos Fluxos Energéticos e Financeiros Associados às Operações da Rede de Mobilidade Elétrica.</p>
            </div>

            <div>
                <h4>4. SERVIÇO PRESTADO AO UVE</h4>
                <p>4.1 Os serviços associados à mobilidade elétrica devem obedecer aos padrões de qualidade estabelecidos nos Regulamentos publicados pela ERSE aplicáveis ao Setor da Mobilidade Elétrica e aos níveis estabelecidos nas presentes Condições. </p>
                <p>4.2 A EGME gere um sistema de informação que permite informar o UVE dos respetivos consumos na rede de mobilidade elétrica, de acordo com métricas de tempo e energia, utilizadas na determinação dos preços dos serviços prestados por parte dos OPC e dos CEME, assim como de outras tarifas, taxas e impostos aplicáveis e indexados as estas mesmas métricas.</p>
                <p>4.3 No caso em que o OPC não dispõe de estabelecimento com caráter fixo ou permanente no qual sejam prestados serviços de atendimento ao público que compreendam o contacto direto com o mesmo, encontra-se dispensado do cumprimento da obrigação de disponibilização de livro de reclamações, devendo, neste caso, disponibilizar no seu sítio na Internet instrumentos que permitam a receção de reclamações dos UVE e afixar, em local bem visível e com carateres facilmente legíveis pelo UVE, um letreiro com indicação de como poderão ser processadas as reclamações.</p>
                <p>4.4 Quaisquer avarias ou falhas técnicas relacionadas com o funcionamento dos pontos de carregamento devem ser comunicadas ao respetivo OPC, para o contacto telefónico afixado no local, que deve estar permanentemente disponível e não comportar quaisquer custos para o UVE, podendo ainda ser comunicadas à EGME e ao respetivo CEME. Mediante acordo prévio entre o OPC e a EGME, o contacto telefónico referido anteriormente poderá ser o da linha de atendimento MOBI.E. </p>
                <p>4.5 Quando pretenda comunicar com a EGME em comunicações urgentes (cabos presos ou dificuldades/impossibilidade de carregamento), o UVE tem disponível a linha de atendimento MOBI.E (24 horas por dia), através do número de telefone: 800 916 624.</p>
                <p>4.6  Não obstante a EGME proceder de imediato à tentativa de resolução, por via remota, dos problemas/avarias identificadas, sempre que se confirme que a origem da falha é no ponto de carregamento (no equipamento), o respetivo OPC será imediatamente notificado, podendo, caso não seja possível resolver o problema/avaria de forma remota, ser chamado a intervir no local.</p>
                <p>4.7 Em caso de ocorrências não urgentes e que não resultem na impossibilidade de o UVE seguir viagem, designadamente as resultantes de situações de cabo preso ou insuficiente autonomia do veículo para a deslocação até ao ponto operacional mais próximo, os UVE poderão entrar em contato com a EGME através do e-mail: mobie@mobie.pt. </p>
                <p>4.8 Em caso de contacto, pedido de informação ou reclamação dirigida por um UVE a um OPC ou à EGME, os UVE deverão identificar-se e comunicar o ID externo do seu Cartão (“Cartão”). </p>
                <p>4.9 No caso das reclamações ou de reporte de avarias/cabos presos, a comunicação entre a EGME e o OPC e o CEME envolvidos deverá conter uma descrição dos motivos reclamados e demais elementos informativos facilitadores ou complementares para a caracterização da situação, além dos elementos identificados no ponto anterior. </p>
                <p>4.10 Todo e qualquer contacto de um UVE realizado através da linha de apoio MOBI.E ou do e-mail mobie@mobie.pt da EGME será transmitido para o respetivo CEME com quem o UVE tem Contrato para o fornecimento de eletricidade para a mobilidade elétrica, para que EGME e CEME disponham da mesma informação.</p>
            </div>

            <div>
                <h4>5. TEMPOS DE RESPOSTA A INCIDENTES E AVARIAS</h4>
                <p>5.1 O tempo máximo de resposta, por parte do OPC, a situações de cabo preso do lado do ponto de carregamento é de quatro horas, iniciando-se a contagem de tempo com a receção, pelo OPC, da comunicação proveniente do UVE ou da EGME.</p>
                <p>5.2 Nas situações que coloquem em causa a segurança dos UVE, dos utentes do local ou da via onde se encontra o ponto de carregamento o tempo máximo de resposta previsto no ponto anterior é reduzido para um máximo de 2 horas, iniciando-se a contagem de tempo com a receção, pelo OPC, da comunicação proveniente do UVE ou da EGME. </p>
                <p>5.3 A reposição das condições normais de funcionamento de um ponto de carregamento cuja avaria não se enquadre nos pontos 5.1. ou 5.2. deverá ser assegurada pelo OPC num máximo de 72 horas, iniciando-se a contagem de tempo com a receção, pelo OPC, da comunicação proveniente do UVE ou da EGME.</p>
                <p>5.4 Em situações excecionais de avaria, que não se enquadrem nos pontos 5.1. e 5.2. e que sejam avaliadas e efetivamente aceites como excecionais pela EGME, o tempo máximo de resposta será alargado até ao limite de 120 horas, iniciando-se a contagem de tempo com a receção, pelo OPC, da comunicação proveniente do UVE ou da EGME.</p>
                <p>5.5 Em situações de avaria ou falhas de comunicação superiores a 72 horas, o ponto de carregamento será temporariamente retirado dos sistemas de informação da EGME, destinados a informar os UVE, de modo a que a informação veiculada pelo sistema não induza os UVE em erro.</p>
                <p>5.6 Logo que as condições de operacionalidade e de segurança do ponto de carregamento sejam restabelecidas, e após verificação do bom funcionamento das comunicações entre o ponto de carregamento e o Sistema de Gestão da EGME, o ponto de carregamento será reintroduzido nos sistemas de informação da EGME. </p>
            </div>

            <div>
                <h4>6. MEIOS DE ACESSO AOS PONTOS DE CARREGAMENTO INTEGRADOS NA REDE DE MOBILIDADE ELÉTRICA</h4>
                <p>6.1 O O acesso de UVE aos pontos de carregamento pode realizar-se de dois modos: </p>
                <ul>
                    <li>Cartão disponibilizado pelo CEME com tecnologia RFID (identificação por rádio frequência) de acesso aos pontos de carregamento da rede de mobilidade elétrica que identifica o UVE na rede, disponibiliza o ponto para carregamento e associa os carregamentos ao presente Contrato;</li>
                    <li> Através de API ou outros meios eletrónicos, que poderão ser disponibilizados pelo CEME, e que permitirão, entre outras funcionalidades, a identificação e autenticação dos respetivos clientes UVE, a visualização dos pontos disponíveis, a seleção do ponto de carregamento a utilizar, assim como dar início e terminar carregamentos de forma remota. </li>
                </ul>
                <p>6.2 O Cartão e outros meios de acesso aos pontos de carregamento integrados na rede de mobilidade elétrica são da exclusiva propriedade do CEME, que procede à sua emissão, ao respetivo pedido de ativação, junto da EGME, na rede de mobilidade elétrica, assim como à associação a um contrato com o UVE.</p>
                <p>6.3 As operações realizadas através da utilização do seu Cartão ou outro meio de acesso consideram-se autorizadas, salvo se tiver sido previamente comunicado o extravio ou o roubo do Cartão.</p>
                <p>6.4 A obrigatoriedade de introdução de PIN para aceder e utilizar o ponto de carregamento depende do tipo de equipamento de carregamento, existindo equipamentos na rede de mobilidade elétrica que dispensam a sua introdução.</p>
                <p>6.5 O Cartão RFID incorpora na frente a identificação externa (“ID Externo”) do mesmo.</p>
                <p>6.6 O Cartão incorpora uma identificação interna (“ID Interno”) que é única, no sentido em que não existe outro Cartão, em todo o mundo, com o mesmo ID Interno, e que apenas é visível com equipamento de leitura.</p>
                <p>6.7 O Cartão é pessoal e intransmissível.</p>
                <p>6.8 Os meios de acesso através de API (tokens e passwords de acesso) e outros meios eletrónicos são pessoais e intransmissíveis, devendo ser do exclusivo conhecimento do Titular, não podendo ser divulgados ou cedidos a terceiros.</p>
                <p>6.9 O UVE Titular deve certificar-se periodicamente de que o Cartão permanece na sua posse, de modo a poder aperceber-se o mais cedo possível de alguma eventual ocorrência.</p>
                <p>6.10 O Cartão deve ser restituído ao CEME que procedeu à sua emissão sempre que se verifique a sua indevida ou inadequada utilização, que coloque em risco a segurança do mesmo, e ainda nos demais casos previstos nas presentes Condições e na lei.</p>
                <p>6.11 O Titular obriga-se a comunicar imediatamente ao respetivo CEME qualquer ocorrência, logo que dela tenha conhecimento, como a perda, o furto, o roubo, a falsificação, a apropriação abusiva ou qualquer utilização não autorizada do Cartão ou dos acessos através de API e outros meios eletrónicos.</p>
                <p>6.12 A comunicação das ocorrências mencionadas no ponto anterior, verificadas quer em Portugal quer no estrangeiro, deverá ser de imediato dirigida ao respetivo CEME, através dos meios identificados nas Condições Particulares, que deverão funcionar 24 horas por dia. Mediante acordo prévio entre o CEME e a EGME, o contacto telefónico referido anteriormente poderá ser o da linha de atendimento MOBI.E.</p>
                <p>6.13 O UVE Titular deverá também participar às autoridades policiais/judiciais locais as ocorrências, apresentando certidão do respetivo auto de denúncia ao respetivo CEME.</p>
                <p>6.14 O acesso a terceiros à informação identificada no ponto anterior deverá ser imediatamente comunicado ao CEME que procedeu à criação e disponibilização dos meios de acesso. </p>
                <p>6.15 Ao mesmo contrato podem estar associados um ou mais Cartões e/ou meios alternativos ou complementares de acesso à rede de mobilidade elétrica, emitidos pelo CEME.</p>
                <p>6.16 Sendo previsível que a data oficial para o início do pagamento nos pontos de carregamento rápido seja anterior à data oficial para o início do pagamento nos pontos de carregamento normal, entre um momento e o outro, o Cartão e restantes meios de acesso continuarão a dar acesso a todos os pontos da rede de mobilidade elétrica, sendo que a sua utilização nos pontos de carregamento normal permanecerá gratuita nessa primeira fase, passando o carregamento nos pontos de carregamento rápido a ser pago de acordo com as Condições Particulares. </p>
                <p>6.17 Em condições excecionais e exclusivamente relacionadas com a segurança dos UVE e dos restantes utilizadores dos espaços nos quais se encontram instalados os pontos de carregamento, a EGME poderá bloquear o acesso a todos os carregamentos no ponto ou pontos em causa. </p>
            </div>
            
            <div>
                <h4>7. BLOQUEIO DO ACESSO À REDE DE MOBILIDADE ELÉTRICA</h4>
                <p>7.1 A utilização do Cartão ou de meios alternativos de acesso aos pontos da rede de mobilidade elétrica pode ser bloqueada pela EGME por motivos objetivos e fundamentados, que se relacionem com:</p>
                <p>a) A segurança da utilização dos pontos de carregamento de baterias de veículos elétricos; </p>
                <p>b) A segurança do sistema de gestão da rede de mobilidade elétrica; </p>
                <p>c) Incumprimento contratual do CEME, com o qual o UVE tem Contrato, perante um ou mais agentes da mobilidade elétrica ou do setor elétrico.</p>
                <p>7.2 A utilização do Cartão ou de meios alternativos de acesso aos pontos da rede de mobilidade elétrica pode ser bloqueada pela EGME a pedido do CEME de acordo com as condições Gerais do Contrato entre o UVE e o CEME. </p>
                <p>7.3 Nos casos referidos nos números anteriores, o CEME informará o UVE Titular, por escrito, do bloqueio da utilização do Cartão e da respetiva justificação, se possível antes de bloquear o Cartão ou, o mais tardar, imediatamente após o bloqueio, salvo se tal informação não puder ser prestada por razões de segurança objetivamente fundamentadas ou se for proibida por outras disposições legais ou regulamentares aplicáveis.</p>
                <p>7.4 Logo que deixem de se verificar os motivos que levaram ao bloqueio do Cartão, o CEME pode solicitar à EGME o desbloqueio da utilização do mesmo.</p>
            </div>

            <div>
                <h4>8. OUTROS DEVERES DOS UVE</h4>
                <p>8.1 É responsabilidade do UVE informar-se devidamente e com a necessária antecedência sobre o funcionamento dos pontos, seguindo as instruções dadas pelo OPC.</p>
                <p>8.2 Sempre que reparar na existência de alguma ocorrência num ponto de carregamento o UVE deverá informar o respetivo OPC. </p>
                <p>8.3 Quando em espaço público, o UVE deverá utilizar o lugar de estacionamento apenas no tempo estritamente indispensável para proceder ao carregamento, sem prejuízo das regras próprias de cada OPC, designadamente, em termos do limite de tempo em que, uma vez terminado o carregamento, o veículo elétrico deve ser retirado do local, sendo o UVE atempadamente informado da situação de incumprimento em que poderá vir a incorrer.</p>
                <p>8.4 Findo o período de extensão estipulado no número anterior, o UVE encontra-se em situação de estacionamento indevido, estando assim sujeito à atuação das entidades fiscalizadoras do estacionamento. </p>
            </div>

            <div>
                <h4>9. POLÍTICA DE PROTEÇÃO DE DADOS PESSOAIS</h4>
                <p>9.1 Sempre que, por motivos operacionais, a EGME tenha acesso a dados pessoais dos UVE, nomeadamente para a realização de notificações em tempo real ou no âmbito de contactos ou de reclamações efetuadas pelos UVE, a EGME trata esses dados pessoais de acordo com os termos previstos na lei, nomeadamente no Regulamento Geral sobre a Proteção de Dados, aprovado pelo Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho, de 27 de abril de 2016 (“RGPD”), bem como em toda a legislação complementar aplicável. </p>
                <p>9.2 Quando a EGME tenha acesso a dados pessoais de um UVE, são facultadas ao referido UVE, nomeadamente, as informações previstas nos artigos 13.º e 14.º do RGPD, consoante, respetivamente, os dados pessoais tenham sido recolhidos junto do UVE, ou os dados pessoais não tenham sido recolhidos junto do UVE. </p>
            </div>

            <div>
                <h4>10. ALTERAÇÃO DAS CONDIÇÕES DE UTILIZAÇÃO DA REDE DE MOBILIDADE ELÉTRICA</h4>
                <p>10.1 As Condições podem ser alteradas mediante comunicação escrita, em suporte papel ou noutro suporte duradouro, dirigida ao UVE Titular.</p>
                <p>10.2 As Condições do presente contrato poderão estar sujeitas a alterações decididas e formalmente comunicadas pela EGME aos CEME, no âmbito das atribuições daquela, mantendo-se, em qualquer situação, a obrigação relativa à comunicação.</p>
                <p>10.3 proposta de alteração das Condições será comunicada com uma antecedência mínima de 14 dias antes da data proposta para a sua entrada em vigor, considerando-se que o UVE Titular aceitou as alterações propostas se não tiver comunicado, por escrito e antes da data proposta para as mesmas entrarem em vigor, que não as aceita.</p>
                <p>10.4 No caso de o UVE Titular não aceitar as alterações propostas, fica o mesmo investido do direito de denunciar o Contrato com o CEME. </p>
            </div>

        </div>
    }

    let Terms;
    switch(lang) {
        case "PT":
            Terms = TermsPT;
            break;
        case "EN":
            Terms = TermsEN;
            break;
        default:
            Terms = TermsPT;
            break;
    }


    return (
        <div>
            <Terms />
        </div>
    )
}
