import React, { useContext, useEffect } from 'react'
import { AppContext } from '../../contexts/AppContext';
import { RegistrationContext } from '../../contexts/RegistrationContext';
import { CHANGE_DIRECT_DEBIT_AGREED, CHANGE_IBAN } from '../../reducers/PaymentInfoReducer';
import isValidIBANNumber from '../../utils/IBANvalidator';
import ErrorValidator from '../common/ErrorValidator';
// import { SET_FORM, SET_ERRORS, SET_PAGE } from "../reducers/reducers";


export default function RegPaymentInfo() {

   const parameters = useContext(RegistrationContext);
   const [paymentState, dispatchPaymentState] = parameters.payment;
   const [errors, setErrors] = parameters.errors;
   const { sendEvent } = useContext(AppContext);
   const { appState } = useContext(AppContext);

   useEffect( () => {
      // setTimer({...timer, payment: Date.now()});
   }, []);
  
   const handleFieldChange = (action, e) => {
      dispatchPaymentState({ type: action, payload: e.target.value });
   }

   const handleCheckboxChange = (action, e) => {
      dispatchPaymentState({ type: action, payload: e.target.checked });
   }

   const validateIban = (value) => {
      if (!isValidIBANNumber(value)) {
         setErrors({...errors, iban: appState.i18n["validations"]["iban"]});
     } else {
        setErrors({...errors, iban: ""});
     }
   }

   const validateDirectDebitAgreed = (value) => {
      if(!value) {
         setErrors({...errors, directDebitAgreed: appState.i18n["validations"]["mandatory"]})
      } else {
         setErrors({...errors, directDebitAgreed: ""})
      }
   }


    return (
        <div>
            <div className="registration-iban registration-area">
                  <span className="registration-area-title">{appState.i18n.registration.directDebit}</span>
                  <label className="label">{appState.i18n["registration"]["iban"]}</label>
                  <input
                     onFocus={ () => sendEvent("Registration", "Focus", "IBAN") }                  
                     name="iban"
                     onChange={e => {
                        validateIban(e.target.value)
                        handleFieldChange(CHANGE_IBAN, e)
                     }}
                     value={paymentState.iban}
                     className="input registration-iban"
                     type="text"
                  />
                  <ErrorValidator error={errors.iban} />
               </div>

               <div>
                  <span className="registration-area-title">{appState.i18n.registration.creditorId}</span>
                  <div className="registrationCreditorFieldContainer">
                     <label className="label">{appState.i18n.registration.creditorName}</label>
                     <div className="registrationCreditorValue">eVaz Energy (GRCApp Unipessoal, Lda)</div>
                  </div>
                  <div className="registrationCreditorFieldContainer">
                     <label className="label">{appState.i18n.registration.creditorId}</label>
                     <div className="registrationCreditorValue">PT14ZZZ114624</div>
                  </div>
                  <div className="registrationCreditorFieldContainer">
                     <label className="label">{appState.i18n.registration.creditorAddress}</label>
                     <div className="registrationCreditorValue">Rua Vicente Barbosa, 5</div>
                  </div>
                  <div className="registrationCreditorFieldContainer">
                     <label className="label">{appState.i18n.registration.creditorPostalCode}</label>
                     <div className="registrationCreditorValue">4710-918</div>
                  </div>
                  <div className="registrationCreditorFieldContainer">
                     <label className="label">{appState.i18n.registration.creditorCity}</label>
                     <div className="registrationCreditorValue">Braga</div>
                  </div>
                  <div className="registrationCreditorFieldContainer">
                     <label className="label">{appState.i18n.registration.creditorIban}</label>
                     <div className="registrationCreditorValue">PT50 0010 0000 4711 5970 0015 9</div>
                     <div style={{marginTop: '20px'}}className="registrationCreditorValue">PT50 0018 2126 0180 1890 0208 5</div>

                  </div>
               </div>

               <div>
                  <label className="label">
                     <input
                        onFocus={ () => sendEvent("Registration", "Focus", "Direct Debit Checkbox") }
                        type="checkbox"
                        name="directDebitAgreed"
                        checked={paymentState.directDebitAgreed === true}
                        onChange={e => {
                           handleCheckboxChange(CHANGE_DIRECT_DEBIT_AGREED, e)
                           validateDirectDebitAgreed(e.target.checked);
                        }}
                     />
                     {appState.i18n["registration"]["iban_disclaimer"]}
                  </label>
                  <ErrorValidator error={errors.directDebitAgreed} />
               </div>
        </div>
    )
}
