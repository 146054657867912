import React from 'react'
import styled from 'styled-components'

export default function Terms({ lang }) {

    const Wrapper = styled.div`
        margin: 0 auto;
        width: 70%;

        @media(max-width: 720px) {
            width: 100%;
        }
    `

    const TermsEN = () => {
        return <Wrapper>
            <div>
                <h2>CONTRACT OF CESSATION AND USE OF eVAZ ENERGY CARD</h2>
                <h4>BETWEEN</h4>
                <p>GRCAPP, UNIPESSOAL LDA, owner of the registered trademark eVAZ ENERGY, with the legal entity registration number 509976719, with headquarters at Rua Vicente Barbosa, 5, Braga municipality, hereinafter referred to as <b>ISSUER</b>;</p>
                <p><b>AND</b></p>
                <p><b>HOLDER</b></p>
                <p>This contract is signed and is guided by the following clauses:</p>
            </div>

            <div>
                <h4>1. PURPOSE OF THE CONTRACT</h4>
                <p>The eVAZ ENERGY CARD is a card issued by the ISSUER that allows its HOLDER to charge electric vehicles at the electric charging stations in Portugal mainland and islands MOBI.E network.</p>
                <p>The card is associated with a license plate and its use is limited to the vehicle with that registration.</p>
            </div>

            <div>
                <h4>2. HOLDER’S RESPONSABILITIES</h4>
                <p>2.1 To correctly use and preserve the card is solely on the account of the HOLDER, who is bound to respect and comply with its rules of use, as described in this document.</p>
                <p>2.2 The HOLDER accepts and is obliged to fulfil the obligations described in the conditions of use of the electric mobility network, defined by the Electric Mobility Manager (EGME).</p>
                <p>These conditions of use are an integral part of this agreement and can be found at the MOBI.E webpage.</p>
            </div>

            <div>
                <h4>3. CARD ISSUANCE</h4>
                <p>3.1 The ISSUER will provide the requested card to the HOLDER free of charge.</p>
                <p>3.2 The eVAZ ENERGY card provided to the HOLDER is associated with a license plate and its use is limited to the vehicle with that registration.</p>
                <p>3.3 In case of a request for a new card resulting from the loss or destruction of the initial one, the ISSUER will charge the HOLDER the amount corresponding to this card issuing which is in effect at the date of the request.</p>
                <p>3.4 The card will be sent by the ISSUER, or whomever the ISSUER designates for this purpose, by regular mail to the address stated in the customer registration.</p>
            </div>

            <div>
                <h4>4. CARD USE</h4>
                <p>4.1 The responsibility for the correct use and conservation of the card rests exclusively with the HOLDER, who is obliged to ensure that the rules for using the card are respected by the respective users, being obliged to transmit the rules for using the card to them.</p>
                <p>4.2 The HOLDER undertakes to immediately communicate to the ISSUER, in writing, by email geral@evazenergy.pt, the loss, loss or theft of each card.</p>
                <p>4.3 The HOLDER shall be liable and bear all losses resulting from unauthorized operations, if they have occurred after the loss, theft, or any other unauthorized use of the card, which has not been communicated under the terms of the previous number.</p>
            </div>

            <div>
                <h4>5. CHARGING POINTS</h4>
                <p>5.1 The HOLDER may use any public charging station included in the MOBI.E network.</p>
                <p>5.2 The availability of the charging station for use is under responsibility of the respective charging station’s operator (OPC).</p>
                <p>5.3 If the charging station is inoperable, the HOLDER should contact, the Electric Mobility Manager (EGME), MOBI.E, through its 24 hour support line 800 916 624.</p>
                <p>5.4 If for some reason unrelated to the ISSUER the eVAZ ENERGY card does not work, particularly for plafond exceeded, expired documents, lack of communication or unknown card error/ deterioration, the ISSUER shall not be liable.</p>
            </div>

            <div>
                <h4>6. CONTRACT DURATION</h4>
                <p>6.1 This contract is valid for a period of one year from its signature and is automatically renovated for successive identical periods, but either party may denounce it by giving prior written notice, sent to the other party with at least 30 (thirty) days.</p>
            </div>

            <div>
                <h4>7. PRICES AND COMMERCIAL CONDITIONS</h4>
                <p>7.1 The price and other commercial conditions in effect are in the annex "Commercial Conditions" which is an integral part of this agreement.</p>
                <p>7.2 he price of the electric power supply, per charge, is composed of the sum of the price of the consumed energy and the rate of access to the grid, according to commercial conditions, plus:</p>
                <ul>
                    <li>Charging Station price charged by the respective OPC;</li>
                    <li>Applicable VAT.</li>
                </ul>
                <p>7.3 The ISSUER reserves the right to make changes in the price to be paid by the HOLDER resulting from the following situations:</p>
                <ul>
                    <li>The amendments approved by ERSE in the tariffs for access to networks and electric mobility management;</li>
                    <li>Legal changes that generate new taxes, fees or any taxes payable by the ISSUER;</li>
                    <li>Changes to electric power purchase costs incurred by ISSUER.</li>
                </ul>
            </div>

            <div>
                <h4>8. BILLING</h4>
                <p>8.1 The ISSUER will issue a monthly invoice based on the consumption data provided by MOBI.E.</p>
                <p>8.2 The ISSUER undertakes to accompany the invoice with detailed information on the consumptions made during the period to which the invoice refers to.</p>
                <p>8.3 The invoice will be addressed to the HOLDER by the ISSUER along with the detailed information, to the email provided at the time of client’s registration.</p>
                <p>8.4 All payments will be done by the HOLDER, by direct debt in an account provided by the client, and which is part of the “Commercial Conditions”, as an integral part of this agreement.</p>
                <p>8.5 The HOLDER authorizes the ISSUER to automatically debit the amounts owed at their due date, for this purpose, indicating the Banking Entity, its agency and provide the respective Bank Debit Authorization</p>
                <p>8.6 The HOLDER shall notify the ISSUER in written form, at least 20 (twenty) days in advance of the closing of the billing period, of any modification of the bank account previously indicated, as well as any significant change in the data for sending the invoice.</p>
                <p>8.7 The ISSUER reserves the right to immediately cancel the present contract, if the HOLDER does not pay the invoice in the maximum term, detailed in “Commercial Conditions” annex to this contract.</p>
                <p>8.8 The HOLDER should immediately inform the ISSUER of the existence of any type of error or irregularities in the received invoices, to geral@evazenergy.pt  within 7 (seven) days from the date of the debit made in the HOLDER’S bank account. If a written complaint is not filled, it is considered that the payment was made according to the invoices and amounts charged.</p>
            </div>

            <div>
                <h4>9. PAYMENT</h4>
                <p>9.1 The invoices issued by the ISSUER are payed through direct debit, between 2 to 5 days counting from the date in which the invoice was sent to the HOLDER’s email.</p>
                <p>9.2 Failure to timely comply with the payment obligation provided for in the preceding paragraph by the HOLDER may cause the ISSUER to terminate this contract, without prior notice.</p>
            </div>

            <div>
                <h4>10. AGREEMENT REVIEW</h4>
                <p>10.1 Any alteration or adaptation to this contract by the ISSUER, namely regarding the commercial conditions set out in the annex, requires a 15-day written notice, being the HOLDER obliged to the new changes if, within the aforementioned period, he does not denounce in writing.</p>
            </div>

            <div>
                <h4>11. COMMUNICATIONS</h4>
                <p>11.1 Information regarding contractual conditions are provided to the HOLDER before the concluding of the present agreement, under the applicable legal terms.</p>
                <p>11.2 The communication between the ISSUER and the HOLDER will be essentially through electronic mail or other digital means that the ISSUER makes available to the HOLDER. Telephone contact with the ISSUER may occasionally be used.</p>
                <p>11.3 The ISSUER undertakes to provide requested clarifications by the HOLDER, within a period of no longer than 7 (seven) days.</p>
            </div>

            <div>
                <h4>12. BREACH OF AGREEMENT AND TERMINATION</h4>
                <p>12.1 Failure to comply with this agreement constitutes just cause for termination for either party, provided it is communicated in a written form.</p>
                <p>12.2 Non-compliance by the HOLDER, grants the ISSUER the right to immediately cancel the use of the cards held by the HOLDER.</p>
                <p>12.3 If the ISSUER decides that the HOLDER’s cards are no longer to be used, he may, at any time, terminate this contract by giving written notice to the HOLDER at least 30 (thirty) days in advance, and without any indemnity to the HOLDER.</p>
                <p>12.4 In case of cards cancellation and/or interruption of supply under the terms mentioned in the above paragraph, the ISSUER will not be obligated to resume supply if the HOLDER does not make all the overdue payments, including interest due and all the expenses resulting from the interruption and the potential supply replenishment.</p>
                <p>12.5 Without prejudice to the preceding paragraph, the delay in the payment of any pecuniary obligation will originate interest, calculated based on the rate fixed under the terms of article 559 of the Civil Code, plus nine percentage points.</p>
                <p>12.6 The judicial collection of debts that result from non-compliance with the terms of this agreement by the HOLDER requires the payment of all costs and charges, including legal costs, lawyers and enforcement agents' fees and any others resulting from the coercive debt collection.</p>
            </div>

            <div>
                <h4>13. DATA PROTECTION</h4>
                <p>13.1 The ISSUER is authorized to process the HOLDER's personal data in accordance with its privacy policy, collected when filling in the data relating to the HOLDER, in all acts necessary for the management of this agreement.</p>
                <p>13.2 The HOLDER’s personal data, collected under this agreement, may be used by the ISSUER and/or its partners, for marketing purposes, as long as that authorization has been given by the HOLDER, at the time of the adhesion.</p>
                <p>13.3 The HOLDER has the right to request the alteration or omission of it personal data, by written request to the email geral@evazenergy.pt in accordance with the legislation in effect regarding the General Regulation on Data Protection, and in accordance with the Privacy Policy informed at the time of registration and that can be consulted online at https://www.evaz.energy</p>
                <p>13.4 Any alteration of the elements part of the present agreement regarding the HOLDER’s identification, must be communicated to the ISSUER, through the email geral@evazenergy.pt. When requested, the  HOLDER must present the proof of the required change.</p>
            </div>

            <div>
                <h4>14. COMPLAINTS</h4>
                <p>14.1 Complaints must be made in written form:</p>
                <ul>
                    <li>Through email, to geral@evazenergy.pt , with the clear indentification of the HOLDER;</li>
                    <li>In the complaints book, in the website www.livroreclamacoes.pt</li>
                </ul>
            </div>

            <div>
                <h4>15. CONFLICT RESOLUTION</h4>
                <p>15.1 The HOLDER may request the intervention of an alternative dispute resolution litigation, in particular through the use of the available arbitration and dispute mediation mechanisms.</p>
                <p>15.2 The HOLDER can resort to any of the entities available for this purpose, listed here: CNIACC – Centro Nacional de Informação e Arbitragem de Conflitos de Consumo (www.arbitragemdeconsumo.org); CIMAAL – Centro de Informação, Mediação e Arbitragem de Conflitos de Consumo do Algarve (www.consumidoronline.pt); Centro de Arbitragem de Conflitos de Consumo do Distrito de Coimbra (www.centrodearbitragemdecoimbra.com); Centro de Arbitragem de Conflitos de Consumo de Lisboa (www.centroarbitragemlisboa.pt); Centro de Informação de Consumo e Arbitragem do Porto (www.cicap.pt); Centro de Arbitragem de Conflitos de Consumo do Vale do Ave (www.triave.pt); Centro de Informação, Mediação e Arbitragem de Consumo (www.ciab.pt); Centro de Arbitragem de Conflitos de Consumo da Madeira (www.srrh.gov-madeira.pt) e Centro de Arbitragem da Universidade Autónoma de Lisboa. (http://arbitragem.autonoma.pt).</p>
                <p>15.3 The list of entities mentioned above is permanently updated by the Directorate General of Consumer at (www.consumidor.gov.pt)</p>
            </div>

            <div>
                <h4>16. APPLICABLE LEGISLATION AND REGULATION</h4>
                <p>16.1 This contract is ruled by the Portuguese law, in particular by the legislation and regulations applicable to the Electric Mobility Sector, currently established in Decree-Law 39/2010, of April 26, and amended by Decree-Law 90/2014, of June 11, and by the Electric Mobility Regulation, approved by <a href="https://www.erse.pt/ebooks/regulamento-da-mobilidade-eletrica/">ERSE Regulation n.º 854/2019, of November 4</a></p>
            </div>

            <div>
                <h4>17. CONTRACT KNOWLEDGE</h4>
                <p>17.1 The HOLDER, after having received the present agreement sent by the ISSUER, declares to have read it in its entirety, having been perfectly informed of its content and scope of its clauses, which expresses his free and spontaneous will, and will sign it.</p>
            </div>
        </Wrapper>

    }

    const TermsPT = () => {
        return <Wrapper>
            <div>
                <h3>CONTRATO DE CEDÊNCIA E UTILIZAÇÃO DO CARTÃO eVAZ ENERGY</h3>
                <h4>ENTRE</h4>
                <p><b>GRCAPP, UNIPESSOAL LDA, proprietária da marca registada eVAZ ENERGY</b>, com o número único de matrícula de pessoa coletiva 509976719, com sede na Rua Vicente Barbosa, 5, concelho de Braga, adiante designada por <b>EMITENTE</b>;</p>
                <p><b>E</b></p>
                <p><b>TITULAR</b>;</p>
                <p>É celebrado o presente contrato que se rege pelas seguintes cláusulas:</p>
            </div>

            <div>
                <h4>1. OBJECTO DO CONTRATO</h4>
                <p>O CARTÃO eVAZ ENERGY é um cartão emitido pelo EMITENTE que permite ao seu TITULAR efectuar o carregamento de veículos elétricos nos postos de carregamento ligados à rede MOBI.E, no território de Portugal Continental e Ilhas.</p>
                <p>O cartão está associado a uma matrícula, estando o seu uso limitado ao veículo com esse registo.</p>
            </div>

            <div>
                <h4>2. OBRIGAÇÕES DO TITULAR</h4>
                <p>2.1 A responsabilidade do correto uso e conservação do cartão corre exclusivamente por conta do TITULAR, que se obriga a respeitar e cumprir as suas regras de utilização conforme descrito no presente documento.</p>
                <p>2.2 O TITULAR aceita e obriga-se também a cumprir as obrigações descritas nas condições de utilização da rede de mobilidade elétrica, definidas pela Entidade Gestora da Mobilidade Elétrica (EGME).</p>
                <p> Estas condições de utilização são parte integrante do presente contrato e podem ser consultadas na página da MOBI.E.</p>
            </div>

            <div>
                <h4>3. EMISSÃO DO CARTÃO</h4>
                <p>3.1 O EMITENTE fornecerá ao TITULAR o cartão por este solicitado gratuitamente. Esta oferta está limitada a um cartão por viatura.</p>
                <p>3.2 O cartão eVAZ ENERGY disponibilizado ao TITULAR está associado a uma matrícula, estando o seu uso limitado ao veículo com essa matrícula.</p>
                <p>3.3 No caso de pedido de emissão de um novo cartão decorrente de extravio ou inutilização do inicial, durante o periodo de um ano a contar da data de emissão do cartão anterior, o EMITENTE cobrará ao TITULAR o valor correspondente ao custo de emissão do cartão que esteja em vigor à data do pedido, bem como da correspondente alteração dos dados.</p>
                <p>3.4 O cartão será enviado pelo EMITENTE, ou quem o EMITENTE designar para o efeito, por correio normal, para a morada de contacto indicada no registo de cliente.</p>
            </div>

            <div>
                <h4>4. UTILIZAÇÃO DO CARTÃO</h4>
                <p>4.1 A responsabilidade do correto uso e conservação do cartão corre exclusivamente por conta do TITULAR, que se obriga a fazer respeitar as regras de utilização do cartão pelos respetivos utilizadores, obrigando-se desde já a transmitir as regras de utilização do cartão aos mesmos.</p>
                <p>4.2 O TITULAR obriga-se a comunicar de imediato ao EMITENTE, por escrito, para o email geral@evazenergy.pt, o extravio, perda ou roubo de cada cartão.</p>
                <p>4.3 O TITULAR terá responsabilidade e suportará todas as perdas resultantes de operações não autorizadas, se as mesmas ocorreram após o extravio, perda, roubo, ou qualquer utilização não autorizada do cartão, que não tenham sido comunicadas nos termos do número anterior.</p>
            </div>

            <div>
                <h4>5. PONTOS DE CARREGAMENTO</h4>
                <p>5.1 O TITULAR poderá aceder a qualquer posto de carregamento de acesso público integrado na rede MOBI.E.</p>
                <p>5.2 A disponibilidade do posto de carregamento para utilização é da responsabilidade do operador do posto de carregamento (OPC) em causa.</p>
                <p>5.3 Em caso de inoperacionalidade do posto de carregamento, deve o TITULAR entrar em contacto com a Entidade Gestora da Mobilidade Elétrica (EGME), MOBI.E, para a sua linha de atendimento disponível 24 horas por dia, através do número de telefone 800 916 624.</p>
                <p>5.4 Se por qualquer motivo o cartão eVAZ ENERGY não funcionar, por alguma razão alheia ao EMITENTE, nomeadamente por plafond excedido, documentos vencidos, falta de comunicação ou cartão desconhecido / deteriorado, o EMITENTE não poderá ser responsabilizado.</p>
            </div>

            <div>
                <h4>6. DURAÇÃO DO CONTRATO</h4>
                <p>6.1 O presente contrato é valido pelo prazo de um ano a contar da data de adesão do TITULAR, prorrogável automaticamente por sucessivos períodos idênticos, podendo contudo qualquer das partes proceder à sua denúncia mediante aviso-prévio escrito enviado à outra parte com a antecedência mínima de 30 (trinta) dias.</p>
            </div>

            <div>
                <h4>7. PREÇO E CONDIÇÕES COMERCIAIS</h4>
                <p>7.1 O preço e demais condições comerciais em vigor são os apresentados no anexo “Condições Comerciais” que é parte integrante deste contrato.</p>
                <p>7.2 O preço do fornecimento de energia elétrica por carregamento é composto pelo somatório do preço da energia consumida e taxa de acesso à rede, conforme condições comerciais, acrescido de:</p>
                <ul>
                    <li>Preço do Posto de Carregamento cobrado pelo respetivo OPC;</li>
                    <li>IVA À taxa legal em vigor.</li>
                </ul>
                <p>7.3 O EMITENTE reserva-se o direito de fazer reflectir no preço a pagar pelo TITULAR alterações que resultem das seguintes situações:</p>
                <ul>
                    <li>As alterações aprovadas pela ERSE nas tarifas de acesso às redes e de gestão da mobilidade elétrica;</li>
                    <li>Alterações legais que gerem novos encargos, taxas ou qualquer tipo de impostos a suportar pelo EMITENTE;</li>
                    <li>Alterações aos custos de aquisição de energia elétrica suportados pelo EMITENTE.</li>
                </ul>
            </div>

            <div>
                <h4>8. FATURAÇÃO</h4>
                <p>8.1 O EMITENTE emitirá a fatura com a periocidade mensal baseada nos dados de consumo a serem disponibilizados pela MOBI.e.</p>
                <p>8.2 O EMITENTE compromete-se a fazer acompanhar a fatura pela informação detalhada dos consumos efectuados durante o período a que a fatura se refere.</p>
                <p>8.3 A fatura será emitida em nome do TITULAR e será enviada, juntamente com o respetivo detalhe, pelo EMITENTE ao TITULAR, em formato digital, para o email registado no momento do registo como cliente.</p>
                <p>8.4 Todos os pagamentos serão efetuados pelo TITULAR, ao EMITENTE, através de débito direto em conta designada pelo próprio, e que consta do anexo “Condições Comerciais” que é parte integrante deste contrato.</p>
                <p>8.5 O TITULAR autoriza o EMITENTE ao débito automático em conta bancária dos valores em dívida na data do seu vencimento, devendo para este efeito, indicar a Entidade Bancária, a sua dependência e fornecer a respetiva Autorização Bancária do Débito em Conta.</p>
                <p>8.6 O TITULAR deverá comunicar por escrito ao EMITENTE com vinte (20) dias de antecedência relativamente ao encerramento do período de faturação, qualquer modificação da conta bancária previamente indicada, bem como qualquer alteração dos dados relevantes para envio da faturação. </p>
                <p>8.7 O EMITENTE reserva-se o direito de anular o contrato agora celebrado de forma imediata, caso o TITULAR não proceda ao pagamento da respectiva fatura no prazo máximo detalhado no anexo a este contrato “Condições Comerciais”. </p>
                <p>8.8 O TITULAR deverá comunicar imediatamente ao EMITENTE, a existência de qualquer erro ou irregularidade nas faturas que lhe sejam enviadas, para o email geral@evazenergy.pt no prazo de 7 (sete) dias a contar da data de um débito em conta do TITULAR, efetuado pela sua instituição bancária. Se não for recebida qualquer reclamação escrita do mesmo, considera-se que o pagamento foi efetuado de acordo com as faturas e os montantes debitados.</p>
            </div>

            <div>
                <h4>9. PAGAMENTO</h4>
                <p>9.1. As faturas emitidas pelo EMITENTE são pagas pelo TITULAR por débito direto em conta, entre 2 a 5 dias contados a partir da data de envio da fatura para o email do TITULAR.</p>
                <p>9.2 O não cumprimento atempado da obrigação de pagamento prevista no número anterior pelo TITULAR poderá determinar a denúncia do presente contrato pelo EMITENTE, sem necessidade de aviso prévio.</p>
            </div>

            <div>
                <h4>10. REVISÃO AO CONTRATO</h4>
                <p>10.1 Qualquer alteração ou adaptação ao presente contrato por parte do EMITENTE, designadamente quanto às condições comerciais constantes do anexo, carece de um pré-aviso escrito de 15 dias, ficando o TITULAR obrigado às novas alterações se, no referido prazo, não denunciar por escrito o presente contrato.</p>
            </div>

            <div>
                <h4>11. COMUNICAÇÕES</h4>
                <p>11.1 As informações sobre as condições contratuais são prestadas ao TITULAR antes da celebração do presente contrato, nos termos legais aplicáveis.</p>
                <p>11.2 A comunicação entre o EMITENTE e o TITULAR será essencialmente através de correio electrónico ou outro meio digital que o EMITENTE coloque à disposição do TITULAR</p>
                <p>11.3 O EMITENTE compromete-se a prestar os esclarecimentos solicitados pelo TITULAR num prazo nunca superior a 7 (sete) dias.</p>
            </div>

            <div>
                <h4>12. INCUMPRIMENTO E RESCISÂO DO CONTRATO</h4>
                <p>12.1 A falta de cumprimento do presente contrato constitui justa causa de rescisão para qualquer uma das partes, desde que comunicada por escrito.</p>
                <p>12.2 O incumprimento por parte do TITULAR confere ao EMITENTE o direito de proceder ao cancelamento imediato do uso dos cartões em posse do TITULAR.</p>
                <p>12.3 O EMITENTE poderá em qualquer momento resolver o presente contrato mediante aviso-prévio escrito enviado ao TITULAR com a antecedência mínima de 30 (trinta) dias, e sem que haja lugar a qualquer indeminização ao TITULAR, caso o EMITENTE decida que os seus cartões deixem de ser utilizados.</p>
                <p>12.4 Em caso de cancelamento do uso dos cartões e/ou interrupção de todos os fornecimentos nos termos do número anterior, o EMITENTE não ficará obrigada a retomar o fornecimento se o TITULAR não realizar todos os pagamentos em dívida, incluindo os juros de mora e todas as despesas resultantes da interrupção e da eventual reposição do fornecimento.</p>
                <p>12.5 Sem prejuízo do número anterior, o atraso no pagamento de qualquer obrigação pecuniária dará lugar à aplicação de juros moratórios, calculados com base na taxa fixada nos termos do artigo 559º do Código Civil, acrescida de nove pontos percentuais.</p>
                <p>12.6 A cobrança judicial de dívidas que sejam decorrentes de incumprimento dos termos do presente contrato pelo TITULAR importa para este o pagamento de todos os custos e encargos, incluindo custas judiciais, honorários de advogados e agentes de execução e quaisquer outros que decorram da cobrança coerciva da dívida.</p>
            </div>

            <div>
                <h4>13. PROTEÇÃO DE DADOS PESSOAIS</h4>
                <p>13.1 O EMITENTE fica autorizado a proceder ao tratamento dos dados pessoais do TITULAR nos termos da sua política de privacidade, recolhida aquando do preenchimento dos dados relativos ao TITULAR, em todos os atos necessários à gestão do presente contrato.</p>
                <p>13.2 Os dados pessoais relativos ao TITULAR, recolhidos no âmbito do presente contrato, poderão ser utilizados pelo EMITENTE e/ou pelos seus parceiros, para acções de marketing, desde que essa autorização tenha sido expressa pelo TITULAR no momento do seu registo.</p>
                <p>13.3 O TITULAR tem direito ao pedido de alteração dos seus dados ou esquecimento, mediante pedido por escrito para o email geral@evazenergy.pt nos termos da legislação em vigor relativa ao Regulamento Geral de Proteção de Dados, e de acordo com a Política de Privacidade informada no momento do registo do TITULAR e que pode ser consultada online em http://evazenergy.pt </p>
                <p>13.4 Qualquer alteração dos elementos constantes do presente contrato relativos à identificação do TITULAR, deve ser comunicada por este ao EMITENTE, através do email geral@evazenergy.pt, devendo ainda o TITULAR apresentar comprovativo da alteração verificada, quando tal lhe for solicitado pelo EMITENTE </p>
            </div>

            <div>
                <h4>14. RECLAMAÇÕES</h4>
                <p>14.1 As reclamações devem ser apresentadas por escrito:</p>
                <ul>
                    <li>Por email, para geral@evazenergy.pt , com identificação inequívoca do TITULAR;</li>
                    <li>No livro de reclamações eletrónico através do sítio na internet em www.livroreclamacoes.pt, se disponível à data da reclamação.</li>
                </ul>
            </div>

            <div>
                <h4>15. RESOLUÇÃO DE CONFLITOS</h4>
                <p>15.1. O TITULAR pode solicitar a intervenção de uma entidade de resolução alternativa de litígios, nomeadamente através do recurso aos mecanismos de arbitragem e mediação de conflitos disponibilizados </p>
                <p>15.2. O TITULAR poderá recorrer a qualquer uma das entidades disponíveis para o efeito, seguidamente elencadas: CNIACC – Centro Nacional de Informação e Arbitragem de Conflitos de Consumo (www.arbitragemdeconsumo.org); CIMAAL – Centro de Informação, Mediação e Arbitragem de Conflitos de Consumo do Algarve (www.consumidoronline.pt); Centro de Arbitragem de Conflitos de Consumo do Distrito de Coimbra (www.centrodearbitragemdecoimbra.com); Centro de Arbitragem de Conflitos de Consumo de Lisboa (www.centroarbitragemlisboa.pt); Centro de Informação de Consumo e Arbitragem do Porto (www.cicap.pt); Centro de Arbitragem de Conflitos de Consumo do Vale do Ave (www.triave.pt); Centro de Informação, Mediação e Arbitragem de Consumo (www.ciab.pt); Centro de Arbitragem de Conflitos de Consumo da Madeira (www.srrh.gov-madeira.pt) e Centro de Arbitragem da Universidade Autónoma de Lisboa. (http://arbitragem.autonoma.pt). </p>
                <p>15.3. A lista de entidades acima referida é permanentemente atualizada pela Direção-Geral do Consumidor (www.consumidor.gov.pt)</p>
            </div>

            <div>
                <h4>16. LEGISLAÇÃO E REGULAMENTAÇÃO APLICÁVEIS</h4>
                <p>16.1 O presente contrato rege-se pelo direito português, em particular pela legislação e regulamentação aplicável ao Setor da Mobilidade Elétrica, atualmente constante do Decreto-Lei n.º 39/2010, de 26 de abril, na redação dada pelo Decreto-Lei n.º 90/2014, de 11 de junho, e pelo Regulamento da Mobilidade Elétrica, aprovado pelo Regulamento da ERSE n.º 879/2015, de 22 de dezembro. </p>
            </div>

            <div>
                <h4>17. CONHECIMENTO (DO CONTRATO)</h4>
                <p>17.1 O TITULAR,  após ter recebido o presente contrato que lhe foi remetido pela EMITENTE, declara ter procedido à sua leitura integral, tendo ficado perfeitamente inteirado do seu conteúdo e alcance das suas cláusulas, as quais  exprimem a sua livre e espontânea vontade, pelo que o vai assinar</p>
            </div>
        </Wrapper>
    }

    let Terms;
    switch (lang) {
        case "PT":
            Terms = TermsPT;
            break;
        case "EN":
            Terms = TermsEN;
            break;
        default:
            Terms = TermsPT;
            break;
    }


    return (
        <div>
            <Terms />
        </div>
    )
}
