import React, { useContext, useEffect } from 'react'
import { Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReactGa from 'react-ga';
import { AppContext } from '../../contexts/AppContext';

export default function RegSuccess() {
    const { appState } = useContext(AppContext);
    

    useEffect( () => {
        ReactGa.event({
            category: 'Registration',
            action: 'Successful Registration',
            value: 100
        });
    }, [])


    return (
        <div className="registration-sucess-container">
            <div style={{display: "flex", height: "2rem", alignItems: "center"}}>
                <span style={{ fontWeight: "bold", fontSize: "20pt"}}>{appState.i18n.registration.successTitle}</span>
                <CheckCircleIcon style={{ fontSize: 28, marginLeft: "9px", paddingTop: "5px", color: "var(--primary-color)" }} />
            </div>
            <p>{appState.i18n.registration.successCopy}</p>
            <p>{appState.i18n.registration.successCopy2}</p>
            <div>
                    <Button 
                        onClick={ () => { window.location.replace("/"); }} 
                        className="input-button button-default shadow">
                        {appState.i18n.navs.finish}
                    </Button>
            </div>
        </div>
    )
}
