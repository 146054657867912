import { Button } from '@material-ui/core';
import React, { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { RegistrationContext } from '../../../contexts/RegistrationContext';
import { DECREASE_PAGE, INCREASE_PAGE } from '../../../reducers/PageNavigatioReducer';
import { SET_NEWREG_CREATED, SET_NEWREG_ID } from '../../../reducers/RegLayoutReducer'
import { isPageValid, _clientInfoState, _plansState, _paymentState, _cardsState, _confirmationState } from '../../../utils/Validator';
import { v4 as uuidv4 } from 'uuid';


const PageNavigation = () => {


    const {appState, sendEvent} = useContext(AppContext)
    const parameters = useContext(RegistrationContext)
    const [pages, pagesDispatch] = parameters.pages;
    const [regLayout, dispatchRegLayout] = parameters.regLayout
    const [errors, setErrors] = parameters.errors
    const [clientInfoState] = parameters.clientInfo
    const [plansState] = parameters.plans
    const [paymentState] = parameters.payment
    const [cardsState] = parameters.cards
    const [confirmationState] = parameters.confirmation
    const apiServer = process.env.GATSBY_API_ENDPOINT;
    

    const onPressNext = () => {
        const canProceed = () => {

            // !Object.values(errors).some( value => value !== "" )
            switch(pages.currentPage) {
                case 0:
                    return isPageValid(0, clientInfoState, appState, clientInfoState, plansState, paymentState, cardsState, confirmationState, errors, setErrors)
                case 1:
                    return isPageValid(1, plansState, appState, clientInfoState, plansState, paymentState, cardsState, confirmationState, errors, setErrors)
                case 2: 
                    return isPageValid(2, paymentState, appState, clientInfoState, plansState, paymentState, cardsState, confirmationState, errors, setErrors)
                case 3:
                    return isPageValid(3, cardsState, appState, clientInfoState, plansState, paymentState, cardsState, confirmationState, errors, setErrors)
                default:
                    return false
                
            }
        }

        const dealWithUpdateRegister = () => {
            if (pages.currentPage === 0 && !regLayout.newRegCreated) {
                createRegister()
                dispatchRegLayout({type: SET_NEWREG_CREATED, payload: true})
            } else {
                updateRegister()
            }
        }

        if (canProceed()) {
            dealWithUpdateRegister()
            pagesDispatch({type: INCREASE_PAGE, payload: {pages}})
            sendEvent("Registration", "Navigation", "Forward to " + pages.pages[pages.currentPage + 1].key);
        } else {
            sendEvent("Registration", "Navigation", "Tried to forward but failed because of validation to page " + pages.pages[pages.currentPage + 1].key);
        }
    }
  
    const onPressBack = () => {
        sendEvent("Registration", "Navigation", "Back from " + pages.pages[pages.currentPage].key);
        pagesDispatch({type: DECREASE_PAGE, payload: {pages}})
    }

    const updateRegister = () => {
        const data = {
            client: {
                info: clientInfoState.clientInfo,
                contacts: clientInfoState.contactInfo,
                language: appState.language,
            },
            plan: plansState,
            payment: paymentState,
            cards: cardsState,
            confirmation: confirmationState,
            metadata: {
                updatedDate: new Date().toISOString(),
            },
        }
        return fetch(`${apiServer}/contracts/newreg/${regLayout.newRegFirebaseId}`, 
            {
                method: "put",
                body: JSON.stringify(data),
                headers: new Headers({
                    "Content-Type": "application/json",
                })
            });
    }

    const createRegister = async () => {
        const data = {
            client: {
                info: clientInfoState.clientInfo,
                contacts: clientInfoState.contactInfo,
                language: appState.language,
            },
            plan: plansState,
            payment: paymentState,
            cards: cardsState,
            confirmation: confirmationState,
            metadata: {
                createdDate: new Date().toISOString(),
                id: uuidv4(),
                updatedDate: new Date().toISOString(),
            },
        }

        return fetch(apiServer + "/contracts/newreg", 
            {
                method: "post",
                body: JSON.stringify(data),
                headers: new Headers({
                    "Content-Type": "application/json",
                })
            })
            .then(res => res.json())
            .then((res) => {
                const id = res.id;
                dispatchRegLayout({type: SET_NEWREG_ID, payload: id})
            });
    }

    if (regLayout.isNavigationVisible) {
            return (
                <div className="hbox registration-navigation">
                    <div style={{"width": "70px"}}>
                        { pages.currentPage > 0 ? <Button onClick={onPressBack} style={{"color": "var(--secondary-type-color)"}}>{appState.i18n["navs"]["back"]}</Button> : null }
                    </div>
                    <div>
                        { pages.currentPage < pages.pages.length - 1 ?<Button onClick={onPressNext} className="input-button button-default shadow">{appState.i18n["navs"]["next"]}</Button> : null }  
                    </div>
                </div>
            )
        } else {
            return null
        }
  }

  export default PageNavigation