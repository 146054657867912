import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';

export default function PricesTable() {

    const { appState } = useContext(AppContext);
    return (
        <div>
            <div className="table_container_width">
                {/* <label>{appState.i18n.prices.priceTable}</label> */}

                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow style={{backgroundColor: "var(--secondary-bg)"}}>
                                <TableCell>{appState.i18n.prices.cycleSchedule.toUpperCase()}</TableCell>
                                <TableCell>{appState.i18n.prices.tarcost}</TableCell>
                                <TableCell>{appState.i18n.prices.pvp}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{appState.i18n.prices.offPeak}</TableCell>
                                <TableCell>€0.14/kWh</TableCell>
                                <TableCell>€0.1734/kWh</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{appState.i18n.prices.peak}</TableCell>
                                <TableCell>€0.19/kWh</TableCell>
                                <TableCell>€0.2349/kWh</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="plans-dragToSeeMore" style={{fontSize: "9pt", color: "var(--secondary-type-color)", marginTop: "5px"}}>{appState.i18n.plans.mobileDragHorizontal}</div>
            </div>
            {/* <label>{appState.i18n.prices.priceTaxNote}</label> */}
            {/* <label>{appState.i18n.prices.networkTaxNote}</label> */}
        </div>
    )
}

