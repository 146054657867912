import React, { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { RegistrationContext } from '../../../contexts/RegistrationContext';

export default function ProgressBar() {
    
    const parameters  = useContext(RegistrationContext);
    const [pages, pagesDispatch] = parameters.pages
    const [regLayout, dispatchRegLayout] = parameters.regLayout
    const {appState} = useContext(AppContext);

    const circleColorCheck = (page, index) => {
        const defaultClass = "registrationProgressBar_circle";
        var classes = "";
        if (index < pages.currentPage) {
            classes = classes + " registrationProgressBar_circle_active"
        } else if (index === pages.currentPage ) {
            classes = classes + " registrationProgressBar_circle_confirmed"
        }
        return defaultClass + classes;
    }

    const getPageName = (pageName) => {
        return appState.i18n.registration[pageName];
    }

    if (regLayout.isProgressBarVisible) {
        return (
            <div className="hbox registrationProgressBar_cont">
                { pages.pages.map( (page, index) => {
                    return (
                        <div key={page.key}className="vbox registrationProgressBar_step_cont">
                            <div className={circleColorCheck(page, index)}>
                                <span className="registrationProgressBar_number">{ index + 1 }</span>
                            </div>
                            <div className="registrationProgressBar_description">{ getPageName(page.name) }</div>
                        </div>
                    )
                })}
            </div>
        )
    } else {
        return null;
    }
}
