import React from 'react';
import RegCards from './RegCards';
import RegClientInfo from './RegClientInfo';
import RegConfirmation from './RegConfirmation';
import RegPaymentInfo from './RegPaymentInfo';
import RegPlan from './RegPlan';

const PageSwitch = ({ page }) => {
    switch (page) {
      case 0:
        return <RegClientInfo/>
      case 1:
        return <RegPlan />
      case 2:
        return <RegPaymentInfo />
      case 3:
        return <RegCards />
      case 4:
        return <RegConfirmation />
      default:
        return null
    }
  }

  export default PageSwitch