import React, { useContext } from 'react'
import { AppContext } from '../../contexts/AppContext';
import PeakHoursTable from './PeakHoursTable';
import ScrollToTop from '../../components/common/ScrollToTop';


export default function Prices() {

    const { appState } = useContext(AppContext)

    return (
        <div className="prices-page-container">
            <div className="plans-pricestable-container">
                <div className="title">{appState.i18n.plans.peakHoursSchedule.toUpperCase()}</div>
                <PeakHoursTable />
            </div>
        </div>
    )
}
