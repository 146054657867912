import React, { useContext, useState, useEffect } from 'react'
// import { SET_ERRORS, SET_NAVIGATION_VISIBILITY, SET_PROGRESSBAR_VISIBILITY, SET_PAGE } from '../reducers/reducers';
import RegSuccess from './RegSuccess';
import Modal from '../common/Modal';
import Terms from './utils/Terms';
import TermsElectricNetwork from './utils/TermsElectricNetwork';
import ReactGa from 'react-ga';
import { v4 as uuidv4 } from 'uuid';
import PrivacyPolicy from './utils/PrivacyPolicy';
import { RegistrationContext } from '../../contexts/RegistrationContext';
import { AppContext } from '../../contexts/AppContext';
import Loading from '../common/Loading';
import { RESET_FORM, SET_NAVIGATION_VISIBILITY, SET_PROGRESSBAR_VISIBILITY } from '../../reducers/RegLayoutReducer';
import { CLIENT_CLEAN_FORM } from '../../reducers/ClientInfoReducer';
import { PLANS_CLEAN_FORM } from '../../reducers/PlansReducer';
import { PAYMENT_CLEAN_FORM } from '../../reducers/PaymentInfoReducer';
import { CARDS_CLEAN_FORM } from '../../reducers/CardsReducer';
import { CONFIRMATION_CLEAN_FORM } from '../../reducers/ConfirmationReducer';
import { PAGE_CLEAN_FORM } from '../../reducers/PageNavigatioReducer';
import { CHANGE_MOBIE_SHARING_CONFIRMED, CHANGE_PARTNERS_SHARING_CONFIRMED, CHANGE_PRIVACY_POLICY_CONFIRMED, CHANGE_TERMS_CONFIRMED } from '../../reducers/ConfirmationReducer';
import ErrorValidator from '../common/ErrorValidator';



export default function RegConfirmation() {
    const parameters = useContext(RegistrationContext);
    const { sendEvent } = useContext(AppContext);
    const {appState} =  useContext(AppContext);

    const [pages, pagesDispatch] = parameters.pages;
    const [clientInfoState, dispatchClientInfo] = parameters.clientInfo;
    const [plansState, dispatchPlans] = parameters.plans;
    const [paymentState, dispatchPaymentState] = parameters.payment;
    const [cardsState, dispatchCardsState] = parameters.cards;
    const [confirmationState, dispatchConfirmationState] = parameters.confirmation
    const [regLayout, dispatchRegLayout] = parameters.regLayout;
    const [errors, setErrors] = parameters.errors;

    const defaultSentState = {
        state: false,
        message: "",
    };

    const [sent] = useState(defaultSentState);
    const [openTerms, setOpenTerms] = useState(false);
    const [openMobie, setOpenMobie] = useState(false);
    const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openError, setOpenError] = useState(false);
    const apiServer = process.env.GATSBY_API_ENDPOINT;
    // const apiServer = "https://web-api-dev-xeu74yuiwq-ue.a.run.app"
    // const apiServer = "http://localhost:5000"
    const mailServer = process.env.GATSBY_MAIL_ENDPOINT;
    // const mailServer = "https://web-mail-dev-xeu74yuiwq-ue.a.run.app";
    // const mailServer = "http://localhost:5000"

    const handleCheckboxChange = (action, e) => {
        dispatchConfirmationState({ type: action, payload: e.target.checked });
     }
    
    useEffect( () => {
        // setTimer({...timer, confirmation: Date.now()});
    }, []);

    const onConfirmForm = () => {

        sendEvent("Registration", "Navigation", "Final Confirmation")
        
        // ReactGa.timing({
        //     category: "Registration",
        //     variable: "Moved to Next Page From",
        //     value: Date.now() - timer.confirmPopup,
        //     label: "confirmPopup"
        // });
        // ReactGa.timing({
        //     category: "Registration",
        //     variable: "Full Registration Time",
        //     value: Date.now() - timer.fullTimer,
        //     label: "user finish"
        // });
        if(confirmationState.confirmed && confirmationState.privacyPolicyAgreed) {
            setLoading(true);
            dispatchRegLayout({type: SET_NAVIGATION_VISIBILITY, payload: false});
            dispatchRegLayout({type: SET_PROGRESSBAR_VISIBILITY, payload: false});
            setTimeout( () => {
                submitForm()
                .then(async function (response) {
                    if (response.ok) {
                        sendMail()
                            .then( result => {
                                if (result.ok) {
                                    // ReactGa.timing({
                                    //     category: "Registration",
                                    //     variable: "Full Registration Time",
                                    //     value: Date.now() - timer.fullTimer,
                                    //     label: "System finish"
                                    // });
                                    const cleanRegForm = () => {
                                        dispatchClientInfo({type: CLIENT_CLEAN_FORM})
                                        dispatchPlans({type: PLANS_CLEAN_FORM})
                                        dispatchPaymentState({type: PAYMENT_CLEAN_FORM})
                                        dispatchCardsState({type: CARDS_CLEAN_FORM})
                                        dispatchConfirmationState({type: CONFIRMATION_CLEAN_FORM})
                                    }
                                    cleanRegForm()
                                    setConfirmed(true);
                                    setLoading(false);
                                } else {
                                    throw new Error("Something in step 2 failed");
                                }
                            })
                            .catch( e => {
                                dispatchRegLayout({type: SET_NAVIGATION_VISIBILITY, payload: true})
                                dispatchRegLayout({type: SET_PROGRESSBAR_VISIBILITY, payload: true})
                                setConfirmed(false);
                                setLoading(false);
                                setOpenError(true);
                            })
                    } else {
                        throw new Error("Something in step 1 failed");
                    } })
                .catch(async function (data) {
                    dispatchRegLayout({type: SET_NAVIGATION_VISIBILITY, payload: true})
                    dispatchRegLayout({type: SET_PROGRESSBAR_VISIBILITY, payload: true})
                    setConfirmed(false);
                    setLoading(false);
                    setOpenError(true);
                });
            }, 500);
        } else {
            setFormConfirmError(true);
        }
    }

    const updateRegister = () => {
        const data = {
            client: {
                info: clientInfoState.clientInfo,
                contacts: clientInfoState.contactInfo,
                language: appState.language,
            },
            plan: plansState,
            payment: paymentState,
            cards: cardsState,
            confirmation: confirmationState,
            metadata: {
                updatedDate: new Date().toISOString(),
            },
        }
        return fetch(`${apiServer}/contracts/newreg/${regLayout.newRegFirebaseId}`, 
            {
                method: "put",
                body: JSON.stringify(data),
                headers: new Headers({
                    "Content-Type": "application/json",
                })
            });
    }

    const onClickFirstConfirm = () => {
        updateRegister()
        if(confirmationState.confirmed && confirmationState.privacyPolicyAgreed) {
            sendEvent("Registration", "Navigation", "Final Confirm Popup OK")
            setFormConfirmError(false)
            setOpenConfirm(true)
            // ReactGa.timing({
            //     category: "Registration",
            //     variable: "Moved to Next Page From",
            //     value: Date.now() - timer.confirmation,
            //     label: "confirmation"
            // });
            // setTimer({...timer, confirmPopup: Date.now()});
        } else {
            setFormConfirmError(true)
        }
    }

    const setFormConfirmError = (newState) => {
        if (newState === true) {
            setErrors({...errors, confirmation: appState.i18n["registration"]["confirmError"]})
        } else {
            setErrors({...errors, confirmation: ""})
        }

    }

    const submitForm = () => {
        const data = {
            client: {
                info: clientInfoState.clientInfo,
                contacts: clientInfoState.contactInfo,
                language: appState.language,
            },
            plan: plansState,
            payment: paymentState,
            cards: cardsState,
            confirmation: confirmationState,
            metadata: {
                createdDate: new Date().toISOString(),
                id: uuidv4()
            },
        };
        return fetch(`${apiServer}/contracts/v2`, {
            method: "post",
            body: JSON.stringify(data),
            headers: new Headers({
                "Content-Type": "application/json",
            })
        }).then(response => {
            if (!response.ok) {
                dispatchRegLayout({type: SET_NAVIGATION_VISIBILITY, payload: true});
                dispatchRegLayout({type: SET_PROGRESSBAR_VISIBILITY, payload: true});
                throw Error(response.statusText);
            }
            return response;
        })
    };

    const sendMail = () => {
        const body = {
            name: clientInfoState.clientInfo.fullName,
            to: clientInfoState.contactInfo.email,
            lang: appState.language,
            city: clientInfoState.contactInfo.city,
            address: clientInfoState.contactInfo.address,
            postalcode: clientInfoState.contactInfo.postalcode1,
            nif: clientInfoState.clientInfo.nif,
            iban: paymentState.iban,
            country: clientInfoState.contactInfo.country,
            alfa: plansState.type === "residential" ? true : false,
            clientType: clientInfoState.clientInfo.type,
            companyName: clientInfoState.clientInfo.companyName
        }

        return fetch(`${mailServer}/mail`, {
            method: "post",
            body: JSON.stringify(body),
            headers: new Headers({
                "Content-Type": "application/json",
            }),
        })
    }

    const LastConfirmation = () => {
        return <div>
            <p>{appState.i18n["registration"]["lastConfirmation"]}</p>
            <input
                readOnly
                className="input-button button-default shadow"
                value={sent.state ? appState.i18n["registration"]["confirmation_sent"] : appState.i18n["registration"]["confirm"]}
                disabled={sent.state}
                onClick={onConfirmForm}
            />
        </div>
    }

    const getPlanLabel = () => {
        let plan;
        switch(plansState.type) {
            case "mobility": 
                plan = appState.i18n.plans.mobility;
                break;
            case "residential":
                plan = appState.i18n.plans.residential;
                break;
            default:
                break;
        }
        return plan;
    }

    const getHourlyRateLabel = () => {
        let energyCycle;
        switch(plansState.energyCycle) {
            case "daily": 
                energyCycle = appState.i18n.registration.plan_daily;
                break;
            case "weekly":
                energyCycle = appState.i18n.registration.plan_weekly;
                break;
            default:
                break;
        }
        return energyCycle;
    }

    const ContractData = () => {
        return (
        <div>
            <div className="registration-area-title" style={{marginBottom: "30px", color:"var(--secondary-type-color)"}}>{appState.i18n["registration"]["confirmationTitle"]}</div>
            <div>
                <label className="confirmation-data-title">{appState.i18n["registration"]["private_name"]}</label>
                <label className="confirmation-data-field" >{clientInfoState.clientInfo.fullName}</label>
            </div>
            <div>
                <label className="confirmation-data-title">{appState.i18n["registration"]["taxnumber"]}</label>
                <label className="confirmation-data-field" >{clientInfoState.clientInfo.nif}</label>
            </div>
            <div>
                <label className="confirmation-data-title">{appState.i18n["registration"]["address"]}</label>
                <label className="confirmation-data-field" >{clientInfoState.contactInfo.address}</label>
            </div>
            <div>
                <label className="confirmation-data-title">{appState.i18n["registration"]["postalcode"]}</label>
                <label className="confirmation-data-field" >{clientInfoState.contactInfo.postalcode1}</label>
            </div>
            <div>
                <label className="confirmation-data-title">{appState.i18n["registration"]["location"]}</label>
                <label className="confirmation-data-field" >{clientInfoState.contactInfo.city}</label>
            </div>
            <div>
                <label className="confirmation-data-title">{appState.i18n["registration"]["country"]}</label>
                <label className="confirmation-data-field" >{clientInfoState.contactInfo.country}</label>
            </div>
            <div>
                <label className="confirmation-data-title">{appState.i18n["registration"]["email"]}</label>
                <label className="confirmation-data-field" >{clientInfoState.contactInfo.email}</label>
            </div>
            <div>
                <label className="confirmation-data-title">{appState.i18n["registration"]["phonenumber"]}</label>
                <label className="confirmation-data-field" >{clientInfoState.contactInfo.phone}</label>
            </div>
            <div>
                <label className="confirmation-data-title">{appState.i18n["registration"]["iban"]}</label>
                <label className="confirmation-data-field" >{paymentState.iban}</label>
            </div>
            <div>
                <label className="confirmation-data-title">{appState.i18n["registration"]["plan"]}</label>
                <label className="confirmation-data-field" >{getPlanLabel()}</label>
                <label className="confirmation-data-field" >{getHourlyRateLabel()}</label>
            </div>
            <div>
                {cardsState.map( 
                    (card, index) => <div key={card.id}>
                        <label className="confirmation-data-title" >{appState.i18n.all.card} #{index+1}</label>
                        <label className="confirmation-data-field" >{card.name}</label>
                        <label className="confirmation-data-field" >{card.licencePlate}</label>
                        <label className="confirmation-data-field" >{card.brand}</label>
                        <label className="confirmation-data-field" >{card.model}</label>
                    </div>
                )}
            </div>
        </div>
        )
    }

    return (
        <div>
            {!confirmed && !loading ? <div>
                <ContractData />
                <Modal 
                    fullScreen={true}
                    title={appState.i18n.registration.termsAndConditions} 
                    content={<Terms lang={appState.language} />} 
                    actions={[{onClick: () => { setOpenTerms(false) }, text: appState.i18n["navs"]["close"]}]}
                    isOpen={[openTerms, setOpenTerms]} />
                <Modal 
                    fullScreen={true}
                    title={appState.i18n.registration.privacyPolicy} 
                    content={<PrivacyPolicy lang={appState.language} />} 
                    actions={[{onClick: () => { setOpenPrivacyPolicy(false) }, text: appState.i18n["navs"]["close"]}]}
                    isOpen={[openPrivacyPolicy, setOpenPrivacyPolicy]} />
                <Modal 
                    fullScreen={true}
                    title={appState.i18n.registration.conditionsMobie} 
                    content={<TermsElectricNetwork lang="PT"/>} 
                    actions={[
                        {
                            onClick: () => { window.location.href = "https://www.mobie.pt/" }, 
                            text: "MOBI.E Website"
                        },
                        {
                            onClick: () => { setOpenMobie(false) }, 
                            text: appState.i18n["navs"]["close"]
                        }
                    ]}
                    isOpen={[openMobie, setOpenMobie]} />
                {/* <Modal 
                    fullScreen={true}
                    title={appState.i18n.registration.prices} 
                    content={<PricesTable />} 
                    actions={[{onClick: () => { setOpenPrices(false) }, text: appState.i18n["navs"]["close"]}]}
                    isOpen={[openPrices, setOpenPrices]} /> */}

               <Modal 
                    title={appState.i18n.registration.registrationFailedTitle} 
                    content={<div><p>{appState.i18n.registration.registrationFailed}</p></div>} 
                    actions={[{onClick: () => { setOpenError(false) }, text: appState.i18n["navs"]["close"]}]}
                    isOpen={[openError, setOpenError]} />  

                <Modal 
                    fullScreen={false}
                    title={appState.i18n.registration.confirm} 
                    content={<LastConfirmation />} 
                    actions={[{onClick: () => { 
                        setOpenConfirm(false);
                        sendEvent("Registration", "Navigation", "Final Confirm Popup LEAVE")
                    }, text: appState.i18n["navs"]["back"]}]}
                    isOpen={[openConfirm, setOpenConfirm]} />  
                        

                <div className="confirmation-links-container">
                    <span onClick={ () => { 
                            setOpenTerms(true) 
                            sendEvent("Registration", "See more", "Terms")
                        }} className="confirmation-links">
                            {appState.i18n["registration"]["termsAndConditions"]}
                    </span>
                    <span onClick={ () => { 
                            setOpenMobie(true)
                            sendEvent("Registration", "See more", "Mobi.e Terms")
                        }} className="confirmation-links">
                            {appState.i18n["registration"]["conditionsMobie"]}
                    </span>
                    <span onClick={ () => { 
                            setOpenPrivacyPolicy(true)
                            sendEvent("Registration", "See more", "Privacy Policy") 
                        }} className="confirmation-links">
                            {appState.i18n["registration"]["privacyPolicy"]}
                    </span>
                </div>
                    
                <div>
                    <div className="confirmTerms">
                        <label>
                            <input
                                onClick={ () => sendEvent("Registration", "Focus", "Privacy Policy Checkbox") }
                                type="checkbox"
                                name="privacyPolicyAgreed"
                                checked={confirmationState.privacyPolicyAgreed === true}
                                onChange={e => handleCheckboxChange(CHANGE_PRIVACY_POLICY_CONFIRMED, e)}
                                />
                            {appState.i18n["registration"]["privacyPolicyTerms"]}
                        </label>
                        <label>
                            <input
                                onClick={ () => sendEvent("Registration", "Focus", "Terms Checkbox") }
                                type="checkbox"
                                name="confirmed"
                                checked={confirmationState.confirmed === true}
                                onChange={e => handleCheckboxChange(CHANGE_TERMS_CONFIRMED, e)}
                            />
                            {appState.i18n["registration"]["registrationTerms"]}
                        </label>
                        <label>
                            <input
                                onClick={ () => sendEvent("Registration", "Focus", "Mobie Marketing Checkbox") }
                                type="checkbox"
                                name="shareDataWithMobie"
                                checked={confirmationState.shareDataWithMobie === true}
                                onChange={e => handleCheckboxChange(CHANGE_MOBIE_SHARING_CONFIRMED, e)}
                            />
                            {appState.i18n["registration"]["shareWithMobie"]}
                        </label>
                        <label>
                            <input
                                onClick={ () => sendEvent("Registration", "Focus", "Partner Marketing Checkbox") }
                                type="checkbox"
                                name="shareForPromotions"
                                checked={confirmationState.shareForPromotions === true}
                                onChange={e => handleCheckboxChange(CHANGE_PARTNERS_SHARING_CONFIRMED, e)}
                            />
                            {appState.i18n["registration"]["shareForPromotions"]}
                        </label>
                    </div>
                    <input
                        readOnly
                        className="input-button button-default shadow"
                        value={sent.state ? appState.i18n["registration"]["confirmation_sent"] : appState.i18n["registration"]["confirm"]}
                        disabled={sent.state}
                        onClick={onClickFirstConfirm}
                    />
                    <ErrorValidator error={errors.confirmation} />
                    {/* { state.form.errors.confirmed ? <span className="fieldValidationErrorLabel">{state.form.errors.confirmed}</span> : null} */}
                    <label className="contacts-sent-thanks">{sent.message}</label>
                </div>
            </div> 
            : loading ? 
            <div>
                <Loading />
            </div> 
            :
            <div>
                <RegSuccess />
            </div> }
        </div>
    )
}
