import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../contexts/AppContext';
import { RegistrationContext } from '../../contexts/RegistrationContext';
import isValidIBANNumber from '../../utils/IBANvalidator';

export default function ErrorValidator({ error }) {

    return (
        <div className="fieldValidationErrorLabel">
            {error}
        </div>
    )
}
