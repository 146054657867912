import isValidIBANNumber from "./IBANvalidator";

export const isPageValid = (pageNumber, state, appState, clientInfoState, plansState, paymentState, cardsState, confirmationState, errors, setErrors) => {
    let errorsArray
    switch(pageNumber) {
        case 0:
            const clientInfoRules = fieldsRuleMapping.clientInfo;
            const contactInfoRules = fieldsRuleMapping.contactInfo;
            let hasErrors = false;
            errorsArray = {}

            Object.entries(clientInfoRules).forEach( field => {
                const errorMessages = getFieldErrors(state.clientInfo[field[0]], field[1], appState, clientInfoState, plansState, paymentState, cardsState, confirmationState)
                if(errorMessages) {
                    hasErrors = true;
                    const prop = field[0]
                    errorsArray[prop] = errorMessages
                }
            })
            Object.entries(contactInfoRules).forEach( field => {
                const errorMessages = getFieldErrors(state.contactInfo[field[0]], field[1], appState, clientInfoState, plansState, paymentState, cardsState, confirmationState)
                if(errorMessages) {
                    hasErrors = true;   
                    const prop = field[0]
                    errorsArray[prop] = errorMessages
                }
            })
            setErrors({...errors, ...errorsArray})
            return !hasErrors
        case 1:
            errorsArray = {}
            if(!plansState.type) {
                hasErrors = true
                errorsArray["plan"] = appState.i18n["validations"]["choosePlan"]
            }
            if(plansState.type === "residential" && !plansState.alreadyAlfaClient) {
                hasErrors = true
                errorsArray["alreadyAlfaClient"] = appState.i18n["validations"]["empty_field"]
            }
            setErrors({...errors, ...errorsArray})
            return !hasErrors
        case 2:
            errorsArray = {}
            if(!isValidIBANNumber(paymentState.iban)) {
                hasErrors = true
                errorsArray["iban"] = appState.i18n["validations"]["iban"]
            } else {
                errorsArray["iban"] = ""
            }
            if (!paymentState.directDebitAgreed) {
                hasErrors = true
                errorsArray["directDebitAgreed"] = appState.i18n["validations"]["empty_field"]
            } else {
                errorsArray["directDebitAgreed"] = ""
            }
            setErrors({...errors, ...errorsArray})
            return !hasErrors

        case 3:
            let errorsArray = {
                cards: [{name: ""}]
            }
            cardsState.forEach( (card, i) => {
                if(!card.name) {
                    if(errorsArray.cards.length > i) {
                        hasErrors = true
                        errorsArray.cards[i].name = appState.i18n["validations"]["empty_field"]
                    } else {
                        hasErrors = true
                        errorsArray.cards.push({name: appState.i18n["validations"]["empty_field"]})
                    }
                }
            })
            setErrors({...errors, ...errorsArray})
            return !hasErrors
        case 4:
            return Object.entries(fieldsRuleMapping.confirmation).every( field => 
                getFieldErrors(state[field[0]], field[1], appState, clientInfoState, plansState, paymentState, cardsState, confirmationState))
    }
}


export const getFieldErrors = (value, rules, appState, clientInfoState, plansState, paymentState, cardsState, confirmationState) => {
    const errorsList = rules.map( rule => ruleChecker(value, rule, appState, clientInfoState, plansState, paymentState, cardsState, confirmationState));
    const isFormValid = (errorsList.length === 1 && errorsList[0] === null);    
    return errorsList.join(" ");
}

const fieldsRuleMapping = {
    clientInfo: {
        companyName: ["companyName"],
        fullName: ["default"],
        nif: ["nif-pt"],
        agentCode: ["agentCode"],
    },
    contactInfo: {
        address: ["default"],
        postalcode1: ["default"],
        city: ["default"],
        email: ["email"],
        phone: ["phone-pt"],
    },
    plans: {
        type: ["plans"],
        alreadyAlfaClient: ["alfaClient"],
    },
    payment: {
        iban: ["iban"],
        directDebitAgreed: ["checkbox"]
    },
    cards: {
        name: ["default"],
    },
    confirmation: {
        privacyPolicyAgreed: ["checkbox"],
        confirmed: ["checkbox"],
    }
}


export const ruleChecker = (value, rule, appState, clientInfoState, plansState, paymentState, cardsState, confirmationState) => {

    const rulesList = {
        "none": (value) => {
            return null;
        },
        "default": (value) => {
            const str = value.replace(/\s+/g, '')
            if (str.length === 0) {
                return appState.i18n["validations"]["empty_field"];
            }
            return null;
        },
        "plans": (value) => {
            if(value) {
                const str = value.replace(/\s+/g, '')
                if (str.length === 0) {
                    return appState.i18n["validations"]["choosePlan"];
                }
                return null;
            }
            return appState.i18n["validations"]["choosePlan"];
        },
        "charsMin": (value) => {
            if (value.length < 5) {
                return appState.i18n["validations"]["too_short"];;
            }
            return null;
        },
        "charsMax": (value) => {
            if (value.length > 20) {
                return appState.i18n["validations"]["too_long"];
            }
            return null;
        },
        "charsExact": (value) => {

        },
        "email": (value) => {
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (!value.match(mailformat)) {
                return appState.i18n["validations"]["email"];
            }
            return null;
        },
        "nif-pt": (value) => {
            if (clientInfoState.contactInfo.country === "Portugal" && (value.length !== 9 || !value.match(/^[0-9]+$/))) {
                return appState.i18n["validations"]["nif_pt"];
            }
            return null;
        },
        "iban": (value) => {
            if (!isValidIBANNumber(value)) {
                return appState.i18n["validations"]["iban"];
            }
            return null;
        },
        "ibanfile": (value) => {
            if (!value) {
                return appState.i18n["validations"]["iban_file"];
            }
            return null;
        },
        "phone-pt": (value) => {
            if (value.length < 9 || !value.match(/^[0-9]+$/)) {
                return appState.i18n["validations"]["phone_pt"];
            }
            return null;
        },
        "checkbox": (value) => {
            if (!value) {
                return appState.i18n["validations"]["empty_field"];
            }
            return null;
        },
        "agentCode": (value) => {
            if (clientInfoState.clientInfo.type === "agent") {
                if (!value) {
                    return appState.i18n["validations"]["empty_field"];
                }
            }
            return null;
        },
        "companyName": (value) => {
            if (clientInfoState.clientInfo.type === "company") {
                if (!value) {
                    return appState.i18n["validations"]["empty_field"];
                }
            }
            return null;
        },
        "alfaClient": (value) => {
            if (plansState.type === "residential") {
                if (!value) {
                    return appState.i18n["validations"]["empty_field"];
                }
            }
            return null;
        },
    }
    return rulesList[rule](value);
}
