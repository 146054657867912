import React, { useContext, useEffect, useState } from 'react'
// import { SET_PAGE, SET_PLAN } from '../reducers/reducers';
import { CHANGE_ENERGY_CYCLE, CHANGE_IS_ALFA_CLIENT } from '../../reducers/PlansReducer'
import PlanCard from '../plans/PlanCard';
import Modal from '../common/Modal';
import ScrollToTop from '../../components/common/ScrollToTop';
import { Button } from '@material-ui/core';
import Prices from '../../utils/Prices.json';
import EnergyCycleDialog from '../plans/EnergyCycleDialog'
import MobilityPricesDialog from '../plans/MobilityPricesDialog'
import ResidentialPricesDialog from '../plans/ResidentialPricesDialog'
import VoltPricesDialog from '../plans/VoltPricesDialog'
import { AppContext } from '../../contexts/AppContext';
import { RegistrationContext } from '../../contexts/RegistrationContext';
import ErrorValidator from '../common/ErrorValidator';
import PricesTable from '../plans/PricesTable';


export default function RegPlan() {


   // const { state, dispatch, timer, setTimer, handleValueChange } = useContext(RegistrationContext);
   const { sendEvent } = useContext(AppContext);
   const { appState } = useContext(AppContext);
   const parameters = useContext(RegistrationContext)
   const [plansState, dispatchPlans] = parameters.plans;
   const [pages] = parameters.pages
   const [errors] = parameters.errors
   const [openPrices, setOpenPrices] = useState(false);
   const [openMobilityPrices, setOpenMobilityPrices] = useState(false);
   const [openResidentialPrices, setOpenResidentialPrices] = useState(false);
   const [openVoltPrices, setOpenVoltPrices] = useState(false);
   const [openCycleTimeTable, setOpenCycleTimeTable] = useState(false);


   // useEffect(() => {
   //    dispatch({ type: SET_PAGE, payload: 1 });
   //    setTimer({...timer, plan: Date.now()})
   // }, []);
   /////////////////////////////////  ref data

   const planOptions = [
      {
         value: "daily",
         label: appState.i18n["registration"]["plan_daily"],
      },
      {
         value: "weekly",
         label: appState.i18n["registration"]["plan_weekly"],
      },
   ];

   const onClickSeePrices = () => {
      setOpenPrices(true);
      sendEvent("Registration", "See more", "Plan Prices")
   }

   const handleChangeCycle = (e) => {
      dispatchPlans({ type: CHANGE_ENERGY_CYCLE, payload: e.target.value })
   }

   const handleFieldChange = (action, e) => {
      dispatchPlans({ type: action, payload: e.target.value });
   }

   const validatePlanChoice = (value) => {

   }


   return (
      <div>
         <ScrollToTop page={pages.currentPage} />
         <div className="registration-input-margin registration-area">

            {
               // PLAN SELECTION SECTION
            }
            <div className="contacts-subtext">
               <span className="subtitle">{appState.i18n["registration"]["plan_subtitle"]}</span>
               {/* <div style={{ marginTop: "20px" }}>
                  <Button onClick={onClickSeePrices}>{appState.i18n.plans.seePrices}</Button>
               </div> */}
            </div>
            <div className="vbox plans_dropdown_and_description_container">

               <div className="plan-cards-cont">

                     <div className="plan-card-and-button">
                        {/* {state.form.errors.type ? <span className="fieldValidationErrorLabel">{state.form.errors.type}</span> : null} */}
                        <PlanCard type={"mobility"} selected={plansState.type === "mobility"} />
                        <Button onClick={() => {
                           setOpenMobilityPrices(true)
                           sendEvent("Registration", "See more", "Mobility")
                        }}>{appState.i18n.plans.seeMore}</Button>
                     </div>
                     <div className="plan-card-and-button">
                        <PlanCard type={"residential"} selected={plansState.type === "residential"} />
                        <Button onClick={() => {
                           setOpenResidentialPrices(true)
                           sendEvent("Registration", "See more", "Residential")
                        }}>{appState.i18n.plans.seeMore}</Button>
                        <div className="plan-card-disclaimer">
                           {appState.i18n.plans.alfaEnergyPlanWarning}
                        </div>
                     </div>

                  {plansState.type === "residential" ?
                     <div style={{ display: "flex", flexDirection: "column" }}>
                        <span className="subtitle" style={{ marginTop: "50px", marginBottom: "20px" }}>{appState.i18n["registration"]["chooseBestOption"]}</span>
                        <div className="clientType-container">
                           <label style={{ cursor: "pointer" }} className="register-clienttype-label">
                              <input
                                 className="register-alfaClient-radio"
                                 type="radio"
                                 name="alreadyAlfaClient"
                                 value="true"
                                 checked={plansState.alreadyAlfaClient === "true"}
                                 // onChange={(e) => { handleValueChange(e, ["alfaClient"]) }}
                                 onChange={e => handleFieldChange(CHANGE_IS_ALFA_CLIENT, e)}
                              />
                              {appState.i18n["registration"]["alreadyAlfaClient"]}
                           </label>
                        </div>

                        <div className="clientType-container">
                           <label style={{ cursor: "pointer" }} className="register-clienttype-label">
                              <input
                                 className="register-alfaClient-radio"
                                 type="radio"
                                 name="alreadyAlfaClient"
                                 value="false"
                                 checked={plansState.alreadyAlfaClient === "false"}
                                 onChange={e => handleFieldChange(CHANGE_IS_ALFA_CLIENT, e)}
                              />
                              {appState.i18n["registration"]["notAlfaClient"]}
                           </label>
                        </div>
                        <ErrorValidator error={errors.alreadyAlfaClient} />

                        {/* { state.form.errors.alreadyAlfaClient ? <span className="fieldValidationErrorLabel">{state.form.errors.alreadyAlfaClient}</span> : null} */}
                     </div>
                     : null
                  }



               </div>
               <div style={{display: "flex", justifyContent: "center", marginTop: "50px"}}>
                  <ErrorValidator error={errors.plan} />
               </div>


               {
                  // PLAN ENERGY CYCLE SECTION
               }
               <div className="contacts-subtext">
                  <span className="subtitle">{appState.i18n["registration"]["energyCycle_subtitle"]}</span>
                  <div style={{ marginTop: "20px" }}>
                     <Button onClick={() => {
                        setOpenCycleTimeTable(true)
                        sendEvent("Registration", "See more", "Cycle Details")
                     }}>{appState.i18n.plans.hoursTimeTable}</Button>
                  </div>
               </div>
               <div>
                  <label className="label">{appState.i18n["registration"]["plan_dropdown_instruction"]}</label>
                  <select
                     required
                     name="energyCycle"
                     onChange={(e) => handleChangeCycle(e)}
                     value={plansState.energyCycle}
                     className="input registration-input-medium plans_input"
                     type="text">
                     {planOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                           {option.label}
                        </option>
                     ))}
                  </select>
               </div>


               {/* <Modal
                  fullScreen={false}
                  title={appState.i18n.registration.prices}
                  content={<Prices />}
                  actions={[{ onClick: () => { setOpenPrices(false) }, text: appState.i18n["navs"]["close"] }]}
                  isOpen={[openPrices, setOpenPrices]} /> */}

               <Modal
                  fullScreen={true}
                  content={<PricesTable 
                     offPeakPrice={Prices.mobility.base.offPeak.price} 
                     peakPrice={Prices.mobility.base.peak.price} 
                     offPeakPVP={Prices.mobility.base.offPeak.pvp} 
                     peakPVP={Prices.mobility.base.peak.pvp} 
                     priceOffPeakDiscount={Prices.mobility.base.offPeak.discountOnPrice} 
                     pricePeakDiscount={Prices.mobility.base.peak.discountOnPrice}
                     showContainer = {false}
                     />}
                  actions={[{ onClick: () => { setOpenMobilityPrices(false) }, text: appState.i18n["navs"]["close"] }]}
                  isOpen={[openMobilityPrices, setOpenMobilityPrices]} />

               <Modal
                  fullScreen={true}
                  content={<PricesTable 
                     offPeakPrice={Prices.mobility.home.offPeak.priceWithDiscount} 
                     peakPrice={Prices.mobility.home.peak.priceWithDiscount} 
                     offPeakPVP={Prices.mobility.home.offPeak.pvpWithDiscount} 
                     peakPVP={Prices.mobility.home.peak.pvpWithDiscount} 
                     priceOffPeakDiscount={Prices.mobility.home.offPeak.discountOnPrice} 
                     pricePeakDiscount={Prices.mobility.home.peak.discountOnPrice}
                     showContainer = {false}
                     />}
                  actions={[{ onClick: () => { setOpenResidentialPrices(false) }, text: appState.i18n["navs"]["close"] }]}
                  isOpen={[openResidentialPrices, setOpenResidentialPrices]} />

               <Modal
                  fullScreen={true}
                  title={appState.i18n.registration.prices}
                  content={<EnergyCycleDialog />}
                  actions={[{ onClick: () => { setOpenCycleTimeTable(false) }, text: appState.i18n["navs"]["close"] }]}
                  isOpen={[openCycleTimeTable, setOpenCycleTimeTable]} />

               {/* <div className="confirmation-links-container">
                     <a onClick={ () => { setOpenPrices(true) } } className="confirmation-links">
                        {appState.i18n["registration"]["prices"]}
                     </a>
                  </div> */}

            </div>
         </div>
      </div>
   )
}
