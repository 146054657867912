import React from 'react'
import styled from 'styled-components';


export default function PrivacyPolicy({ lang }) {

    const Wrapper = styled.div`
        margin: 0 auto;
        width: 70%;

        @media(max-width: 720px) {
            width: 100%;
        }
    `




    const PolicyEN = () => {
        return <div>
            <div>
                <p>The personal data collected in this registration, and that potentially identify the subject to whom they belong, will be stored and treated according to the General Data Protection Regulation (GDPR)</p>
                <p>This data will be used in the fulfillment of the services contract and kept up to 3 years after ceasing of the contract</p>
            </div>



        </div>

    }

    const PolicyPT = () => {
        return <Wrapper>
            <div>
                <h2>Objetivo</h2>
                <p>A eVaz Energy está comprometida e respeita a privacidade dos utilizadores, bem como a proteção e a segurança dos seus dados pessoais. O utilizador garante que é maior de idade e que os dados comunicados são verdadeiros, exatos, completos e atuais, sendo responsável por qualquer desconformidade relacionada com os mesmos. Se porventura o utilizador disponibilizar dados pessoais pertencentes a um terceiro, o utilizador garante que informou prévia e expressamente esse terceiro sobre as condições previstas neste documento e que obteve a sua autorização para facultar os seus dados à eVaz Energy para as finalidades indicadas.</p>
                <h2>Âmbito de aplicação e responsável pelo tratamento de dados</h2> 
                <p>Esta política de privacidade aplica-se a todos os dados pessoais recolhidos nos sítios eletrónicos com os endereços sueletricidade.pt (&quot;website&quot;) e login.sueletricidade.pt (&quot;Área reservada&quot;) (em conjunto &quot;canais digitais&quot;), que operam sob a marca eVaz Energy</p>
                <p>A entidade responsável pelo tratamento dos referidos dados é: </p>
                <p>GRCAPP, UNIPESSOAL LDA, proprietária da marca registada eVAZ ENERGY, com o número único de matrícula de pessoa coletiva 509976719, com sede na Rua Vicente Barbosa, 5, concelho de Braga</p>
                <p>Pode contactar a eVaz Energy sobre qualquer questão relacionada com a presente política de privacidade ou com o tratamento dos seus dados pessoais, indicando como assunto “eVaz Energy - Proteção de dados pessoais” para o endereço de correio eletrónico geral@evazenergy.pt ou para o endereço Rua Vicente Barbosa, 5, 4710-918 Braga</p>
                <p>Finalidades do tratamento e fundamento jurídico Os dados recolhidos no website da eVaz Energy são tratados para as seguintes finalidades:</p>
                <ul>
                    <li>Processar pedidos de formalização de novos contratos de fornecimento de energia elétrica com fundamento na realização de diligências pré-contratuais a pedido do titular dos dados;</li>
                    <li>Processar, com fundamento na execução do contrato de fornecimento de energia elétrica, pedidos de adesão de pagamento das faturas pelo método de débito direto e pedidos de adesão à fatura em formato eletrónico;</li>
                    <li>Receber comunicações de leituras com fundamento na execução do contrato de fornecimento de energia elétrica em vigor entre o utilizador e a eVaz Energy;</li>
                    <li>Solicitar, com fundamento na execução do contrato de fornecimento de energia elétrica, o contacto da eVaz Energy para responder a questões ou pedidos de informação relacionadas com o referido contrato;</li>
                    <li>Responder a questões colocadas pelo utilizador, cujo tratamento é necessário para assegurar a resposta com fundamento nos interesses legítimos da eVaz Energy em responder a pedidos de esclarecimento sobre o fornecimento de energia.</li>
                </ul>
                <p>Adicionalmente, se for titular de um contrato de fornecimento de energia elétrica em vigor com a eVaz Energy e criar uma conta de acesso ao referido contrato na Área reservada, a eVaz Energy tratará os seus dados pessoais para as seguintes finalidades:</p>
                <p>viabilizar o pagamento de faturas; permitir a consulta de informação e detalhe de faturas, bem como o download das mesmas; processar pedidos de alterações das condições contratuais e pedidos de pagamento das faturas pelo método de débito direto e pedido de envio da fatura em formato eletrónico; receber comunicações de leituras registadas pelo equipamento de medição instalado no local de consumo (vulgo contador); permitir a consulta dos consumos efetuados e debitados; elaborar, submeter e consultar os pedidos de informação e/ou reclamações; consulta de detalhe de outros documentos. No contexto das operações acima referidas, o tratamento de dados pessoais é realizado com fundamento na execução do contrato de fornecimento de energia elétrica entre o utilizador e a eVaz Energy ou terá fundamento para a realização das diligências pré-contratuais a pedido do utilizador.</p>
                <h2>Destinatários</h2>
                <p>O tratamento dos dados pessoais dos utilizadores poderá ser realizado por um prestador de serviços idóneo, contratado pela eVaz Energy. O referido prestador de serviços tratará os dados exclusivamente para as finalidades estabelecidas pela eVaz Energy e em observância das instruções por esta emitidas, cumprindo rigorosamente as normas legais sobre proteção de dados pessoais, segurança da informação e demais legislação aplicável.</p>
                <h2>Prazo de conservação</h2>
                <p>A eVaz Energy conservará os dados pessoais dos utilizadores durante o prazo de vigência do contrato com a eVaz Energy, acrescido dos prazos de prescrição e caducidade dos direitos dele decorrentes, salvo se for obrigada a mantê-los por prazo mais longo por força da legislação e regulamentação aplicável. Nas situações em que os dados pessoais dos utilizadores, que não são clientes da eVaz Energy, forem tratados com vista à resposta a questões suscitadas, os referidos dados serão eliminados após o encerramento do processo de resposta a essas questões.</p>
                <h2>Direitos dos utilizadores</h2>
                <p>O utilizador poderá, em qualquer momento, exercer os seus direitos de acesso, retificação, eliminação e portabilidade dos seus dados, assim como o direito de oposição e limitação ao seu tratamento, nas circunstâncias previstas pela legislação, a partir da área de ajuda do website ou através de carta registada para o endereço postal Rua Vicente Barbosa, 5, 4710-918 Braga ou para o endereço de correio eletrónico geral@evazenergy.pt</p>
                <p>O utilizador também tem o direito de apresentar uma reclamação à Comissão Nacional de Proteção de Dados ou a outra autoridade de controlo que seja competente no país onde reside ou onde trabalha, se entender que os seus direitos sobre os seus dados pessoais foram infringidos.</p>
                <p>Os direitos acima referidos consistem no seguinte:</p>
                <h4>Direito de Acesso</h4>
                <p>significa que o titular dos dados tem o direito de obter informação sobre os dados pessoais que a eVaz Energy trata e determinadas informações sobre a forma como esses dados são tratados. Este direito permite-lhe ter conhecimento e confirmar que a eVaz Energy trata os seus dados em conformidade com as leis de proteção de dados aplicáveis. eVaz Energy poderá, contudo, recusar a prestação da informação solicitada sempre que, para o fazer, tenha de revelar dados pessoais de outra pessoa ou a informação solicitada prejudique os direitos de outra pessoa;</p>
                <h4>Direito de Retificação</h4>
                <p> significa que o titular dos dados tem o direito de solicitar à eVaz Energy a tomada de medidas razoáveis para corrigir os seus dados pessoais que estejam incorretos ou incompletos; </p>
                <h4>Direito ao Apagamento dos Dados</h4>  
                <p>também conhecido como o “direito a ser esquecido”, significa que o titular dos dados poderá solicitar o apagamento ou a eliminação dos seus dados, desde que não existam fundamentos válidos para que a eVaz Energy continue a tratá-los; </p>
                <h4>Direito à Limitação do Tratamento</h4>
                <p>significa que o titular dos dados tem o direito de que os seus dados só possam ser tratados, com exceção da sua conservação, mediante o seu consentimento ou para efeitos de declaração, exercício ou defesa de um direito num processo judicial, de defesa dos direitos de outra pessoa singular ou coletiva, ou por motivos ponderosos de interesse público da União ou de um Estado-Membro, enquanto a eVaz Energy avalia um pedido de retificação ou como alternativa ao apagamento;</p>
                <h4>Direito à Portabilidade dos Dados</h4>
                <p>significa que o titular dos dados tem o direito de obter e reutilizar determinados dados pessoais para os seus fins próprios. Este direito somente se aplica aos dados pessoais que tenham sido fornecidos pelo titular dos dados à eVaz Energy, com base num contrato ou consentimento e que a mesma trate através de meios automatizados; </p>
                <h4>Direito de Oposição</h4>
                <p>significa que o titular dos dados tem o direito de se opor aos tratamentos que tenham por base a prossecução de interesses legítimos da eVaz Energy, por motivos relacionados com a sua situação particular, a qualquer altura em que decorra esse tratamento. Para qualquer questão relacionada com a presente política de privacidade ou com o tratamento dos seus dados pessoais, o utilizador poderá ainda contactar o encarregado de proteção de dados (DPO) da eVaz Energy, através do endereço de correio eletrónico geral@evazenergy.pt.</p>
                <h2>Caráter obrigatório dos dados solicitados</h2>
                <p>Os dados constantes dos formulários disponíveis nos canais digitais que estejam identificados com um asterisco (*) serão de preenchimento obrigatório, na medida em que são necessários para prosseguir a finalidade em questão (p.ex., para criar uma conta na Área reservada, para aceder a determinados serviços da Área reservada ou para colocar questões no formulário de contacto do website). Como tal, se o utilizador não facultar os mesmos, a eVaz Energy não poderá atender o seu pedido.</p>
                <h2>Medidas de proteção dos dados pessoais</h2>
                <p>A eVaz Energy aplica diversas medidas técnicas e organizativas adequadas para proteger os dados pessoais dos utilizadores, incluindo utilização de servidores seguros, firewalls e encriptação de dados em comunicações.</p>
                <h2>Alterações à Política de privacidade</h2>
                <p>A eVaz Energy reserva o direito de, a todo o momento, sem aviso prévio e com efeitos imediatos, mas sem prejuízo dos direitos legais conferidos aos titulares dos dados, alterar, acrescentar ou revogar, parcial ou totalmente, a presente Política de Privacidade. Quaisquer alterações serão imediatamente divulgadas nos canais digitais e canais de comunicação habitualmente utilizados.</p>
                <p>Caso a eVaz Energy altere de forma substancial a forma como trata os seus dados pessoais e, consequentemente, a presente Política de Privacidade, notificará o titular dos dados acerca das referidas alterações através dos meios de contacto que tenha obtido.</p>
            </div>
        </Wrapper>
    }

    let Terms;
    switch (lang) {
        case "PT":
            Terms = PolicyPT;
            break;
        case "EN":
            Terms = PolicyEN;
            break;
        default:
            Terms = PolicyPT;
            break;
    }


    return (
        <div>
            <Terms />
        </div>
    )
}