import React, { useContext, useEffect, useState } from "react"
import Layout from "../../components/layout"
import Image from "../../components/image"
import SEO from "../../components/seo"
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from "../../contexts/AppContext";
import PageSwitch from "../../components/register/PageSwitch";
import { Button } from "@material-ui/core";

import { useImmerReducer } from "use-immer";
import { DECREASE_PAGE, INCREASE_PAGE, pageNavigationReducer, SET_PAGE } from "../../reducers/PageNavigatioReducer";
import { clientInfoReducer } from "../../reducers/ClientInfoReducer";
import { RegistrationContext } from "../../contexts/RegistrationContext";
import ProgressBar from "../../components/register/utils/ProgressBar";
import PageNavigation from "../../components/register/utils/PageNavigation";
import { SET_NAVIGATION_VISIBILITY, SET_PROGRESSBAR_VISIBILITY } from "../../reducers/RegLayoutReducer";


const RegistrationIndex = () => {

  const { appState } = useContext(AppContext);
  const parameters = useContext(RegistrationContext);

  const [pages, pagesDispatch] = parameters.pages;
  const [regLayout, dispatchRegLayout] = parameters.regLayout;
  const [valid, setValid] = useState(false);
  const [formErrors, setFormErrors] = useState({})
  const [timer, setTimer] = useState({})

  useEffect(() => {
    pagesDispatch({type: SET_PAGE, payload: 0})
    dispatchRegLayout({type: SET_NAVIGATION_VISIBILITY, payload: true})
    dispatchRegLayout({type: SET_PROGRESSBAR_VISIBILITY, payload: true})
    return () => {
      // cleanup
    }
  }, [])
  





  // const PageNavigation = () => {

  //   const onPressBack = () => {
  //     dispatch({type: DECREASE_PAGE, payload: {pages} })
  //     updateRegister();
  //   }

  //   const onPressNext = () => {
  //     if(state.page === 0) {
  //         createRegister();
  //     } else {
  //         updateRegister();
  //     }
  //     if (validatePageFields()) {
  //         dispatch({type: INCREASE_PAGE, payload: {pages}})
  //         ReactGa.timing({
  //             category: "Registration",
  //             variable: "Moved to Next Page From",
  //             value: Date.now() - parseInt(timer[window.location.pathname.split("/")[2]]),
  //             label: window.location.pathname.split("/")[2]
  //         });
  //         ReactGa.timing({
  //             category: "Registration",
  //             variable: "Partial Time At",
  //             value: Date.now() - timer.fullTimer,
  //             label: window.location.pathname.split("/")[2]
  //         });
  //         // console.log(window.location.pathname.split("/")[2], Date.now() - parseInt(timer[window.location.pathname.split("/")[2]]))
  //         // console.log("full timer:", Date.now() - timer.fullTimer)
  //     }
  //     sendEvent("Registration", "Navigation", "Forward to " + pages[state.page + 1].key);
  //   }

  //   if (layout.isNavigationVisible) {
  //       return (
  //           <div className="hbox registration-navigation">
  //               <div style={{"width": "70px"}}>
  //                   { currentPage > 0 ? <Button onClick={onPressBack} style={{"color": "var(--secondary-type-color)"}}>{appState.i18n["navs"]["back"]}</Button> : null }
  //               </div>
  //               <div>
  //                   { currentPage < pages.length-1 ?<Button onClick={onPressNext} className="input-button button-default shadow">{appState.i18n["navs"]["next"]}</Button> : null }  
  //               </div>
  //           </div>
  //       )
  //   } else {
  //       return null;
  //   }
  // }








  console.log("register")
  return <Layout>
    <SEO title="Registo" />
    <div className="Registration-master-cont registration-cont container-safety-border">
      <ProgressBar />
      <PageSwitch page={pages.currentPage} />
      <PageNavigation />
    </div>
  </Layout>
}

export default RegistrationIndex;
