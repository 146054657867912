import React, { useContext, useEffect } from 'react'
import { Button } from '@material-ui/core';
// import { ADD_CARD, REMOVE_CARD, CARD_FIELD_UPDATE, SET_CARD_ERRORS, SET_PAGE } from '../reducers/reducers'
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RegistrationContext } from '../../contexts/RegistrationContext';
import { AppContext } from '../../contexts/AppContext';
import { ADD_CARD, CHANGE_CARD_CAR_BRAND, CHANGE_CARD_CAR_MODEL, CHANGE_CARD_CLIENT_NAME, CHANGE_CARD_LICENCE_PLATE, REMOVE_CARD } from '../../reducers/CardsReducer';
import ErrorValidator from '../common/ErrorValidator';





const CardItem = ({id, index, sendEvent}) => {


    const parameters = useContext(RegistrationContext);
    const { appState } = useContext(AppContext);
    const [cardsState, dispatchCardsState] = parameters.cards;
    const [errors, setErrors] = parameters.errors

    const onRemoveCard = (id, index) => {
        const newCards = [...errors.cards] 
        newCards.splice(index, 1);
        setErrors({...errors, cards: newCards})
        setTimeout( () => {
            dispatchCardsState({type: REMOVE_CARD, payload: {id: id, index: index}});
        }, 100);
    }

    const handleFieldChange = (action, e, index, id) => {
        dispatchCardsState({ type: action, payload: {value: e.target.value, index: index, id: id} });
    }

    const validateCardName = (value, index) => {
        value = value.replace(/\s+/g, '')
        if(!value) {
            const newCards = [...errors.cards]
            newCards[index].name = appState.i18n["validations"]["empty_field"]
            setErrors({...errors, cards: newCards})
        } else {
            const  newCards = [...errors.cards]
            newCards[index].name = ""
            setErrors({...errors, cards: newCards})
        }
    }

    // const validateCardField = (name, value, rules, id, index) => {
    //     const errorsList = rules.map( rule => ruleChecker(value, rule));
    //     const formValid = (errorsList.length === 0 && errorsList[0]);
    //     const errorMessage = errorsList.join(" ");
        
    //     if (!formValid) {
    //         dispatch({ type: SET_CARD_ERRORS, payload: { name, errorMessage, id, index } })
    //     }
         
    // }

    // const handleCardFieldChange = (e, id, rules, index) => {
    //     dispatch({type: CARD_FIELD_UPDATE, payload: {name: e.target.name, value: e.target.value, itemId: id, index}})
    //     validateCardField(e.target.name, e.target.value, rules, id, index)
    // }
    return (
        <div>
            <div className="hbox cards-container" style={ index%2 !== 0 ? {"backgroundColor": "var(--secondary-bg)" } : null }>
                <div className="hbox">
                    <div className="cards-fieldinput-container">
                        <label className="label">{appState.i18n["registration"]["card_name"]}</label>
                        <input
                            onClick={ () => sendEvent("Registration", "Focus", "Card Name") }
                            name="name"
                            onChange={(e) => {
                                handleFieldChange(CHANGE_CARD_CLIENT_NAME, e, index, id)
                                validateCardName(e.target.value, index)
                            }}
                            value={cardsState[index].name}
                            className="input registration-input-medium"
                            type="name"
                            minLength="1"
                            maxLength="100"
                            autoComplete="name"
                        />
                        {/* { state.form.errors.cards[id].name ? <span className="fieldValidationErrorLabel">{state.form.errors.cards[id].name}</span> : null} */}
                        {errors.cards.length > index ? <ErrorValidator error={errors.cards[index].name} /> : null}
                    </div>
                    <div className="cards-fieldinput-container">
                        <label className="label">{appState.i18n["registration"]["card_licenceplate"]}</label>
                        <input
                            onClick={ () => sendEvent("Registration", "Focus", "Card Licence") }
                            name="licencePlate"
                            onChange={(e) => {handleFieldChange(CHANGE_CARD_LICENCE_PLATE, e, index, id)}}
                            value={cardsState[index].licencePlate}
                            className="input registration-input-xs"
                            type="text"
                            minLength="4"
                            maxLength="20"
                        />
                        {/* { state.form.errors.cards[id].licencePlate ? <span className="fieldValidationErrorLabel">{state.form.errors.cards[id].licencePlate}</span> : null} */}
                    </div>
                    <div className="cards-fieldinput-container">
                        <label  className="label">{appState.i18n["registration"]["card_brand"]}</label>
                        <input
                            onClick={ () => sendEvent("Registration", "Focus", "Card Car Brand") }
                            name="brand"
                            onChange={(e) => {handleFieldChange(CHANGE_CARD_CAR_BRAND, e, index, id)}}
                            value={cardsState[index].brand}
                            className="input registration-input-xs"
                            type="text"
                            minLength="1"
                            maxLength="20"
                        />
                        {/* { state.form.errors.cards[id].brand ? <span className="fieldValidationErrorLabel">{state.form.errors.cards[id].brand}</span> : null} */}
                    </div>
                    <div className="cards-fieldinput-container">
                        <label className="label">{appState.i18n["registration"]["card_model"]}</label>
                        <input
                            onClick={ () => sendEvent("Registration", "Focus", "Card Car Model") }
                            name="model"
                            onChange={(e) => {handleFieldChange(CHANGE_CARD_CAR_MODEL, e, index, id)}}
                            value={cardsState[index].model}
                            className="input registration-input-xs"
                            type="text"
                            minLength="1"
                            maxLength="25"
                        />
                        {/* { state.form.errors.cards[id].model ? <span className="fieldValidationErrorLabel">{state.form.errors.cards[id].model}</span> : null} */}
                    </div>
                </div>
                <Button 
                    onClick={ () => { 
                        onRemoveCard(id, index);
                        sendEvent("Registration", "Cards", "Remove Card")
                    }} 
                    className="cards-removeCardIcon" ><FontAwesomeIcon  icon={ faTrash } /></Button>
            </div>
        </div>
    )
}

export default function RegCards() {
    
    const { sendEvent } = useContext(AppContext);
    const {appState} = useContext(AppContext);
    const parameters = useContext(RegistrationContext);
    const [cardsState, dispatchCardsState] = parameters.cards;
    const [errors, setErrors] = parameters.errors

    // const { state, dispatch, ruleChecker, timer, setTimer } = useContext(RegistrationContext);
    
    useEffect( () => {
        // setTimer({...timer, cards: Date.now()});
    }, []);

    
    const createCard = () => {
        dispatchCardsState({type: ADD_CARD});
        setErrors({...errors, cards: [...errors.cards, {name: ""}]})
        sendEvent("Registration", "Cards", "Add Card")
    }
    
    

    return (
        <div>
            <div className="registration-input-margin registration-area">
                <div className="registration-area-title">{appState.i18n["registration"]["cards_title"]}</div>
                <div className="register-licencePlate-cont">
                { cardsState.map( (card, index) => 
                    <CardItem key={card.id} id={card.id} index={index} sendEvent={sendEvent} />
                    )}
                </div>
                <div>
                    <Button onClick={createCard}>{appState.i18n["registration"]["card_add"].toUpperCase()}</Button>
                </div>
            </div>
        </div>
    )
}
