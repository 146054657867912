import React, { useContext } from 'react'
import mobilityIcon from '../../images/icons/car_mobilityplan.svg'
import residentialIcon from '../../images/icons/housecar_residentialplan.svg'
import eiLogo from '../../images/partners/exame-informatica-volt-logos.png'
import { CHANGE_IS_ALFA_CLIENT, CHANGE_SELECTED_PLAN } from '../../reducers/PlansReducer';
import { AppContext } from '../../contexts/AppContext';
import { RegistrationContext } from '../../contexts/RegistrationContext';



export default function PlanCard({type}) {

    const { sendEvent } = useContext(AppContext);
    const {appState} = useContext(AppContext);
    
    const card = {
        logo: "",
        plan: "",
        description: ""
    }
    
    const parameters = useContext(RegistrationContext);
    const [plansState, dispatchPlans] = parameters.plans;
    
    switch(type) {
        case "mobility":
            card.logo = mobilityIcon;
            card.plan = appState.i18n.plans.mobility;
            card.description = appState.i18n.plans.mobilityDescription;
            break;
        case "residential":
            card.logo = residentialIcon;
            card.plan = appState.i18n.plans.residential;
            card.description = appState.i18n.plans.residentialDescription;
            break;
        case "volt":
            card.logo = eiLogo;
            card.plan = appState.i18n.plans.volt;
            card.description = appState.i18n.plans.voltDescription;
            break;
        default:
            card.logo = mobilityIcon;
            card.plan = appState.i18n.plans.mobility;
            card.description = appState.i18n.plans.mobilityDescription;
            break;
    }

    const getClasses = () => {
        let classes = "plan-card-container shadow";
        if (plansState.type === type) {
            classes = classes + " plan-card-selected"
        }
        if (type === "volt") {
            classes = classes + " plan-card-volt";
        } else if (type === "mobility") {
            classes = classes + " plan-card-mobility";
        } else if (type === "residential") {
            classes = classes + " plan-card-residential";
        }
        return classes;
    }

    const onSelectCard = (e, type) => {
        dispatchPlans({type: CHANGE_SELECTED_PLAN, payload: type })
        dispatchPlans({type: CHANGE_IS_ALFA_CLIENT, payload: null});
        sendEvent("Registration", "Choose Plan", type) 
    }
    
            
    return (
        <div className={getClasses()} onClick={e => onSelectCard(e, type)}>
            <div className="plan-card-header">
                <img className="plan-card-icon" src={card.logo} alt="card logo" />
            </div>
            <div className="plan-card-content">
                <div className="plan-card-titles">
                    <span className="plan-card-content-title">{appState.i18n.plans.cardtitle}</span>
                    <span className="plan-card-content-planName">{card.plan}</span>
                </div>
                
                <span className="plan-card-content-description">{card.description}</span>
                {type === "mobility" ? <span className="plan-card-secondary-label">{appState.i18n.plans.mobilitySideNote}</span> : null }
                {/* {type === "residential" ? <img style={{marginTop: "20px"}}src={alfaLogo} height="77px" alt="Alfa Logo" /> : null} */}
            </div>
        </div>
    )
}
